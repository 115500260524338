import { Fragment, useState } from "react";
import { connect } from "react-redux";

import {
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AppViewInfo from "../dialogs/AppViewInfo";
import no_image from "../../images/no_image.png";
import { ConfirmDialog } from "../dialogs";
import { client } from "../../actions";

import { useTheme } from "@mui/material/styles";

function AppCard({
  client,
  deleteApplication,
  scopes,
}) {
  const [showAppInfo, setShowAppInfo] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  
  const theme = useTheme();

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleDeleteSubmit = () => {
    deleteApplication(client.id);
    handleDeleteClose();
  };

  return (
    <Fragment>
      <ConfirmDialog
        open={openDelete}
        handleClose={handleDeleteClose}
        handleSubmit={handleDeleteSubmit}
      >
        ¿Está seguro que desea eliminar la aplicación {client?.client_name}?,
        esta acción es irreversible.
      </ConfirmDialog>
      <AppViewInfo
        open={showAppInfo}
        setOpen={setShowAppInfo}
        client={client}
        scopes={scopes}
      />
      <Card
        sx={{
          minWidth: 345,
          maxWidth: 345,
          backgroundColor: theme.palette.background.custom,
          backgroundImage: "none",
        }}
        key={client.id}
      >
        <CardMedia
          component="img"
          weight="140"
          height="140"
          image={client.logo ? (`data:image/png;base64,${client.logo}`) : no_image}
          alt={`${client.client_name} logo`}
        />
        <CardContent sx={{ minHeight: 120 }}>
          <Typography gutterBottom variant="h5" component="div">
            {client.client_name}
          </Typography>
          <Typography>
            {client.description}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "space-between" }}>
          <div>
            <Button
              size="small"
              onClick={() => {
                window.open(client.client_uri, "_black").focus();
              }}
            >
              Visitar
            </Button>
            <Button
              size="small" 
              onClick={() => setShowAppInfo(true)}
            >
              Ver Información
            </Button>
          </div>
          <div>
            <IconButton
              size="small"
              color="error"
              onClick={() => setOpenDelete(true)}
            >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </div>
        </CardActions>
      </Card>
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    client: ownProps.client,
    scopes: ownProps.scopes,
  };
};

const mapActionToProps = {
  deleteApplication: client.deleteClient,
};

export default connect(mapStateToProps, mapActionToProps)(AppCard);