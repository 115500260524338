import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";

import { withPermissions } from "../../hocs";
import { ADMIN_USERS_READ, ADMIN_USERS_WRITE } from "../../constants";

const UserMenu = () => {
  return (
    <ListItem button component={Link} to="/users">
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={"Usuarios"} />
    </ListItem>
  );
};

export default withPermissions(
  UserMenu,
  [ADMIN_USERS_READ, ADMIN_USERS_WRITE],
  false
);
