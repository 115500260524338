import { useState } from "react";

/**
 * Dado un formulario, proporciona las validaciones necesarias del mismo.
 * 
 * Requiere un objeto que contenga los valores 
 * iniciales del formulario, y sus respectivas validaciones.
 * Además requiere que el componente reciba por props una función para enviar los datos.
 * 
 * ```js 
 * // Ejemplo de uso
 * const initialValues = {
 *   name: "",
 *   description: "",
 *  };
 * const validate = {
 *   name: (name) => mustHaveMaxSize(name, 20),
 *   description: (description) => mustHaveMaxSize(description, 255),
 * };
 * 
 * const sender = async (values) => {
 *   // Enviar los datos al servidor
 * }:
 * 
 * const MyForm = (props) => {
 *   ...
 * 
 *   <button onClick={(e) => props.handleSubmit(e, props.sender)}> Enviar </button>
 * };
 * 
 * export default withValidation(MyForm, { initialValues, validate });
 * ```
 * @param {React.FC<any>} FormComponent 
 * @param {object} IValidationsProps 
 * @returns {React.FC<any>} Nuevo componente, con los siguientes props:
 *  - `handleSubmit`: Función que se ejecuta al hacer submit.
 *  - `handleChange`: Función que se ejecuta al cambiar el valor de un campo.
 *  - `handleBlur`: Función que se ejecuta al perder el foco en un campo.
 *  - `errors`: Objeto que contiene los errores de validación.
 *  - `values`: Objeto que contiene los valores del formulario.
 *  - `touched`: Objeto que contiene los campos que han sido tocados.
 */
const withValidation = (FormComponent, { initialValues, validate }) => {
  const WrappedForm = ({ ...props }) => {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const handleChange = (evt, options) => {
      let name, newValue, type;

      if (evt === undefined || evt.target.name === undefined) {
        name = options.name;
        newValue = options.value;
        type = options.type;
      } else {
        name = evt.target.name;
        newValue = evt.target.value;
        type = evt.target.type;
      }
      // Campos numéricos se mantienen numéricos.
      const value = type === "number" ? +newValue : newValue;

      setValues({
        ...values,
        [name]: value,
      });


      // Limpiar los errores si el campo fue modificado por el usuario.
      setTouched({
        ...touched,
        [name]: true,
      });
    };

    const handleBlur = (evt, options) => {
      let name, value;

      if (evt === undefined || evt.target.name === undefined) {
        name = options.name;
        value = options.value;
      } else {
        name = evt.target.name;
        value = evt.target.value;
      }

      // Limpiar errores que se hayan ejecutado previamente
      const { [name]: removedError, ...rest } = errors;

      // Buscamos un nuevo error
      const error = validate[name](value);

      // Validamos el campo si ha sido modificado.
      setErrors({
        ...rest,
        ...(error && { [name]: touched[name] && error }),
      });
    };



    const handleSubmit = async (evt, sendDataFn) => {
      evt.preventDefault();
      const formValidation = Object.keys(values).reduce(
        (acc, key) => {
          if (!validate[key]) {
            return acc
          }
          const newError = validate[key](values[key]);
          const newTouched = { [key]: true };
          return {
            errors: {
              ...acc.errors,
              ...(newError && { [key]: newError }),
            },
            touched: {
              ...acc.touched,
              ...newTouched,
            },
          };
        },
        {
          errors: { ...errors },
          touched: { ...touched },
        }
      );
      setErrors(formValidation.errors);
      setTouched(formValidation.touched);

      if (
        !Object.values(formValidation.errors).length && // No hay errores
        Object.values(formValidation.touched).length ===
        Object.values(values).length && // Todos los campos fueron modificados
        Object.values(formValidation.touched).every((t) => t === true) // Todos los campos fueron modificados
      ) {
        await sendDataFn(values);
      }
    };

    const onEnterKeyPress = async (evt, sendDataFn) => {
      if (evt.key === 'Enter') {
        await handleSubmit(evt, sendDataFn);
      }
    }

    return (
      <FormComponent
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        onEnterKeyPress={onEnterKeyPress}
        errors={errors}
        touched={touched}
        values={values}
        {...props}
      />
    );
  };

  return WrappedForm;
};

export default withValidation;