import { useEffect } from "react";
import { connect } from "react-redux";

import { ProfileForm, validate, initialValues } from "../forms/ProfileForm";
import { withValidation } from "../../hocs";
import { profile } from "../../actions";
import FormDialog from "./FormDialog";

function ProfileDialog({
  open,
  setOpen,
  close,
  createProfile,
  message,
  errors,
  isLoading,
}) {
  const Form = withValidation(ProfileForm, { initialValues, validate });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (close) handleClose();
  }, [close]);


  return (
    <FormDialog
      open={open}
      title="Crear Perfil"
      description={
        <span>
          Ingresa los datos solicitados para crear un perfil de usuario tuyo en
          Amadeus. <br />
          Todas las aplicaciones asociadas a Amadeus podrán hacer uso de esta
          Informacion para una atencion personalizada.
        </span>
      }
      showMessage={errors && message !== null}
      errorMessage={message}
      form={
        <Form
          modalClose={handleClose}
          hasToDisableButton={isLoading || message !== null}
          senderDataFn={createProfile}
        />
      }
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    open: ownProps.open,
    setOpen: ownProps.setOpen,
    close: state.displayAlert.display,
    message: state.profile.message,
    errors: state.profile.errors,
    isLoading: state.profile.isLoading,
  };
};

const mapActionToProps = {
  createProfile: profile.createProfile,
};

export default connect(mapStateToProps, mapActionToProps)(ProfileDialog);
