import {
  USER_GET_LOADING,
  USER_GET_SUCCESS,
  USER_GET_FAILED,
  USER_CREATE_LOADING,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILED,
  USER_UPDATE_LOADING,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILED,
  USER_DELETE_LOADING,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED,
  USER_RESET_MESSAGES,
} from "../actions/types";

const initialState = {
  isLoading: false,
  errors: false,
  message: null,
  data: [],
};

export default function get_users(state = initialState, action) {
  switch (action.type) {
    case USER_RESET_MESSAGES:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: null,
      };
    case USER_GET_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Obteniendo usuarios",
      };
    case USER_CREATE_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Creando usuario...",
      };
    case USER_UPDATE_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Actualizando usuario...",
      };
    case USER_DELETE_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Eliminando usuario...",
      };
    case USER_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: action.payload.users,
      };
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: [...state.data, action.payload.user],
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: state.data.map((user) => 
          user.id === action.payload.user.id
            ? action.payload.user
            : user
        ),
      };
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: state.data.filter(
          (user) => user.id !== action.payload.id
        ),
      };
    case USER_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
        data: [],
      };
    case USER_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      };
    case USER_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      };
    case USER_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      };
    default:
      return { ...state };
  }
}
