import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";

const InfoTable = (props) => {
  const theme = useTheme();
  
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={props.rows}
        columns={props.columns}
        pageSize={7}
        density="compact"
        disableSelectionOnClick
        rowsPerPageOptions={[5, 7, 10]}
        sx={{
          borderColor: theme.palette.primary.main,
        }}
        components= {{ Toolbar: GridToolbar }}
      />
    </div>
  );
};

export default InfoTable;
