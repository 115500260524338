
export const TEXT_PRIMARY_COLOR = "#d0d0d0";
export const ICON_PRIMARY_COLOR = "#d0d0d0";
export const PRIMARY_BACKGROUND_COLOR = "#4F545C";
export const SECONDARY_BACKGROUND_COLOR = "#333333";
export const PRIMARY_NAVBAR_BACKGROUND_COLOR = "#333333";
export const PRIMARY_SIDEBAR_BACKGROUND_COLOR = "#333333";
export const PRIMARY_PANEL_BACKGROUND_COLOR = "#4F545C";
export const PRIMARY_PANEL_TEXT_COLOR = "#d0d0d0";
export const PRIMARY_DIALOG_BACKGROUND_COLOR = "#333333";
export const TITLE_DIALOG_TEXT_COLOR = "#0098d4";
export const TEXT_COLOR_FORM_V1 = "#d0d0d0";
export const DESCRIPTION_DIALOG_TEXT_COLOR = "#d0d0d0";
export const BUTTON_TEXT_COLOR = "#d0d0d0";
export const BUTTON_BACKGROUND_COLOR = "#333333";
export const PRIMARY_DUAL_LOGO_COLOR = "#0098d4";
export const HEAD_TABLE_BACKGROUND_COLOR = "#202225";
export const BODY_TABLE_BACKGROUND_COLOR = "#121315";
export const CHIP_BACKGROUND_COLOR = "#4F545C";
