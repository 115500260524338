import { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Button, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { AppDialog } from "../dialogs";
import { client } from "../../actions";
import AppCard from "./AppView";
import { withPermissions } from "../../hocs";
import { APPS_DEV_READ, APPS_DEV_WRITE } from "../../constants";
import PageHeader from "../headers/PageHeader";
import * as clientAPI from "api/client";


function DevAppView({
  message,
  errors,
  isLoading,
  clients,
  getClients
}) {
  const [showAppDialog, setShowAppDialog] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [scopes, setScopes] = useState([]);

  const CreateApplication = withPermissions(
    () => ( 
      <Button
        variant="outlined"
        sx={{
          fontWeight: 600,
        }}
        onClick={() => {
          setFormValues(null);
          setShowAppDialog(true)
        }}
      >
        Crear Aplicación
      </Button>
    ),
    [APPS_DEV_WRITE],
    false
  );

  useEffect(() => {
    getClients();
  }, [getClients]);

  useEffect(async () => {
    const response = await clientAPI.getAvailableScopes();
    if (response.status) {
      setScopes(response.data.resources);
    }
  }, []);

  return (
    <div style={{ marginLeft: "325px", marginRight: "2%" }}>
      <AppDialog
        open={showAppDialog}
        setOpen={setShowAppDialog}
        formValues={formValues}
        setFormValues={setFormValues}  
      />
      <PageHeader
        title="Aplicaciones"
        children={<CreateApplication />}
      />
      {isLoading ? (
        <CircularProgress size={40} />
      ) : errors && clients === [] ? (
        message
      ) : clients.length !== 0 ? (
        <Grid container spacing={1} sx={{ marginTop: "5px" }}>
          {
            clients.map((client, index) => (
              <Grid item key={index}>
                <AppCard client={client} scopes={scopes}/>
              </Grid>
            ))
          }
        </Grid>
      ) : (
        "No se han encontrado aplicaciones registradas en amadeus"
      )    
    }

    </div>
  );
}

const mapStateToProps = (state) => ({
  clients: state.client.data,
  message: state.client.message,
  errors: state.client.errors,
  isLoading: state.client.isLoading,
});

const mapActionToProps = {
  getClients: client.getClients,
};

export default withPermissions(
  connect(mapStateToProps, mapActionToProps)(DevAppView),
  [APPS_DEV_READ, APPS_DEV_WRITE]
);
