import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AppsIcon from '@mui/icons-material/Apps';

import { withPermissions } from "../../hocs";
import { APPS_DEV_READ, APPS_DEV_WRITE } from "../../constants";

const ApplicationMenu = () => {
  return (
    <ListItem button component={Link} to="/applications">
      <ListItemIcon>
        <AppsIcon />
      </ListItemIcon>
      <ListItemText primary={"Aplicaciones"} />
    </ListItem>
  );
};

export default withPermissions(
  ApplicationMenu,
  [APPS_DEV_READ, APPS_DEV_WRITE],
  false
);
