import { useState, useEffect } from "react";
import { connect } from "react-redux";

import { client } from "actions";
import * as clientAPI from "api/client";

import AppForm from "components/forms/AppForm";
import FormDialog from "./FormDialog";

function AppDialog({
  open,
  setOpen,
  close,
  createApplication,
  editApplication,
  isLoading,
  error,
  message,
  formValues,
  setFormValues,
}) {
  const [scopes, setScopes] = useState([]);

  const handleClose = () => {
    setFormValues(null);
    setOpen(false);
  };

  const onSubmit = (data) => {
    return !formValues
      ? createApplication(data)
      : editApplication(formValues.id, data);
  }

  useEffect(() => {
    if (close) handleClose();
  }, [close]);

  useEffect(async () => {
    const response = await clientAPI.getAvailableScopes();
    if (response.status) {
      setScopes(response.data.resources);
    }
  }, []);

  return (
    <FormDialog
      open={open}
      title="Crear Aplicación"
      description={
        <span>
          Estás apunto de crear una Aplicación en Amadeus. <br />
          Ingresa los datos solicitados.
        </span>
      }
      showMessage={error && !!message}
      errorMessage={message}
      form={
        <AppForm
          scopes={scopes}
          values={formValues?.data}
          submit={onSubmit}
          handleClose={handleClose}
          disableButton={isLoading || (message && message!=="OK")}  
          submitButtonText={
            !formValues ? "Crear Aplicación" : "Editar Aplicación"
          }
        />
      }
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.client.isLoading,
    error: state.client.errors,
    message: state.client.message,
    close: state.displayAlert.display,
    open: ownProps.open,
    setOpen: ownProps.setOpen,
    formValues: ownProps.formValues,
    setFormValues: ownProps.setFormValues,
  };
};

const mapActionToProps = {
  createApplication: client.createClient,
  editApplication: client.updateClient,
};

export default connect(mapStateToProps, mapActionToProps)(AppDialog);
