import { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";


import { usernameRules, passwordRules, emailRules } from "./rules";

const UsersForm = ({
  isEdit,
  values,
  roles,
  submit,
  handleClose,
  disableButton,
  submitButtonText,
}) => {
  const initialValues = {
    username: "",
    password: "",
    email: "",
    roles: [],
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [showPassword, setShowPassword] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: values ?? initialValues,
  });

  const onSubmit = (data) => {
    submit(data);
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Controller
          name="username"
          rules={usernameRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                required
                disabled={isEdit}
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                label="Nombre de Usuario"
                variant="standard"
                color="primary"
                helperText={error?.message}
                sx={{ mb: 2 }}
              />
            );
          }}
        />
        { 
          !isEdit && (
            <Controller
              name="password"
              required
              rules={passwordRules}
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error, isTouched },
              }) => {
                return (
                  <FormControl
                    error={error !== undefined}
                    variant="standard"
                    color="primary"
                    fullWidth
                    required
                    sx={{ mb: 2 }}
                  >
                    <InputLabel htmlFor={name}>Password</InputLabel>
                    <Input
                      id={name}
                      name={name}
                      value={value}
                      type={showPassword ? "text" : "password"}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      required
                      label="Password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {isTouched && error && (
                      <FormHelperText>{error.message}</FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
          )
        }
        <Controller
          name="email"
          rules={emailRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                required
                disabled={isEdit}
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                label="Correo Electrónico"
                variant="standard"
                color="primary"
                helperText={error?.message}
                sx={{ mb: 2 }}
              />
            );
          }}
        />
        <Controller
          name="roles"
          required
          control={control}
          render={({
            field: { onChange, value },
          }) =>{
            return (
              <Autocomplete
                multiple
                value={value}
                options={roles}
                disableCloseOnSelect
                onChange={(_, data) => {
                  onChange(data);
                }}
                getOptionLabel={(option) => option.name }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, { selected }) => {
                  return (<li {...props}>
                    <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    />
                    {option.name} ({option.permissions.length} Permisos)
                    </li>
                  )}
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Roles"
                    variant="standard"
                    color="primary"
                    sx={{ mt: 1, mb: 1 }}
                  />
                )}
              />
            );
          }}
        />
        <Box sx={{ mt: 2, textAlign: "end" }}>
          <Button onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" disabled={disableButton}>
            {submitButtonText ?? "Enviar"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UsersForm;
