import {
  RESOURCE_SERVER_GET_LOADING,
  RESOURCE_SERVER_GET_SUCCESS,
  RESOURCE_SERVER_GET_FAILED,
  RESOURCE_SERVER_CREATE_FAILED,
  RESOURCE_SERVER_CREATE_SUCCESS,
  RESOURCE_SERVER_CREATE_LOADING,
  RESOURCE_SERVER_EDIT_FAILED,
  RESOURCE_SERVER_EDIT_SUCCESS,
  RESOURCE_SERVER_EDIT_LOADING,
  RESOURCE_SERVER_DELETE_FAILED,
  RESOURCE_SERVER_DELETE_SUCCESS,
  RESOURCE_SERVER_DELETE_LOADING,
  RESOURCE_SERVER_RESET_MESSAGES,
} from "./types";

import * as resourceServerAPI from "../api/rserver";
import { displayAlert } from "./display_alert";

const resourceServerGetLoading = () => {
  return {
    type: RESOURCE_SERVER_GET_LOADING,
  };
};

const resourceServerCreateLoading = () => {
  return {
    type: RESOURCE_SERVER_CREATE_LOADING,
  };
};

const resourceServerEditLoading = () => {
  return {
    type: RESOURCE_SERVER_EDIT_LOADING,
  };
};

const resourceServerDeleteLoading = () => {
  return {
    type: RESOURCE_SERVER_DELETE_LOADING,
  };
};

const resourceServerGetSuccess = (rservers, message) => {
  return {
    type: RESOURCE_SERVER_GET_SUCCESS,
    payload: { message, rservers },
  };
};

const resourceServerCreateSuccess = (rserver, message) => {
  return {
    type: RESOURCE_SERVER_CREATE_SUCCESS,
    payload: { message, rserver },
  };
};

const resourceServerEditSuccess = (rserver, message) => {
  return {
    type: RESOURCE_SERVER_EDIT_SUCCESS,
    payload: { message, rserver },
  };
};

const resourceServerDeleteSuccess = (id, message) => {
  return {
    type: RESOURCE_SERVER_DELETE_SUCCESS,
    payload: { id, message },
  };
};

export const resourceServerGetFailed = (message) => {
  return {
    type: RESOURCE_SERVER_GET_FAILED,
    payload: message,
  };
};

export const resourceServerCreateFailed = (message) => {
  return {
    type: RESOURCE_SERVER_CREATE_FAILED,
    payload: message,
  };
};

export const resourceServerEditFailed = (message) => {
  return {
    type: RESOURCE_SERVER_EDIT_FAILED,
    payload: message,
  };
};

export const resourceServerDeleteFailed = (message) => {
  return {
    type: RESOURCE_SERVER_DELETE_FAILED,
    payload: message,
  };
};

export const resourceServerResetMessages = () => {
  return {
    type: RESOURCE_SERVER_RESET_MESSAGES,
  };
};

export const getResourceServers = () => {
  return async (dispatch) => {
    dispatch(resourceServerGetLoading());

    const { status, data, message } = await resourceServerAPI.getResourceServers();

    if (status) {
      dispatch(
        resourceServerGetSuccess(data.rservers, message)
      );
    } else {
      dispatch(resourceServerGetFailed(message));
    }

    dispatch(resourceServerResetMessages());
  };
};

export const createResourceServer = (newResourceServer) => {
  return async (dispatch) => {
    dispatch(resourceServerCreateLoading());

    const { status, data, message } = await resourceServerAPI.createResourceServer(
      newResourceServer
    );

    if (status) {
      dispatch(
        resourceServerCreateSuccess(data.rserver, message)
      );
      dispatch(
        displayAlert(
          `Servidor de recursos ${newResourceServer.server_name} creado con éxito`
        )
      );
    } else {
      dispatch(resourceServerCreateFailed(message));
    }

    setTimeout(() => {
      dispatch(resourceServerResetMessages());
    }, 2000);
  };
};

export const editResourceServer = (id, newResourceServer) => {
  return async (dispatch) => {
    dispatch(resourceServerEditLoading());

    const { status, data, message } = await resourceServerAPI.editResourceServer(id, newResourceServer);
    
    if (status) {
      dispatch(
        resourceServerEditSuccess(data.rserver, message)
      );
      dispatch(
        displayAlert(
          `Servidor de recursos ${newResourceServer.server_name} editado con éxito`
        )
      );
    }
    else {
      dispatch(resourceServerEditFailed(message));
    }

    setTimeout(() => {
      dispatch(resourceServerResetMessages());
    }, 2000);
  };
}

export const deleteResourceServer = (id) => {
  return async (dispatch) => {
    dispatch(resourceServerDeleteLoading());

    const { status, message } = await resourceServerAPI.deleteResourceServer(id);

    if (status) {
      dispatch(
        resourceServerDeleteSuccess(id, message)
      );
      dispatch(
        displayAlert(
          `Servidor de recurso eliminado con éxito`
        )
      );
    }
    else {
      dispatch(resourceServerDeleteFailed(message));
    }

    setTimeout(() => {
      dispatch(resourceServerResetMessages());
    }, 2000);
  };
}
