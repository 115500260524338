import { useState } from "react";
import { Link } from "react-router-dom";

import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ManageSearch from "@mui/icons-material/ManageSearch";

import { withPermissions } from "hocs";
import { APPS_DEV_READ, APPS_DEV_WRITE } from "constants";

function TestingMenu() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <ManageAccountsIcon />
        </ListItemIcon>
        <ListItemText primary={"Testing"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ ml: 2 }}>
          <ListItem button component={Link} to="/testing">
            <ListItemIcon>
              <ManageSearch />
            </ListItemIcon>
            <ListItemText primary={"Component Test"} />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}

export default withPermissions(
  TestingMenu,
  [APPS_DEV_READ, APPS_DEV_WRITE],
  false
);
