import { getData, postData, putData, deleteData } from "./base";

export const getOrganizations = async () => {
    return await getData("organization");
}

export const createOrganization = async (newOrg) => {
    return await postData("organization", newOrg);
}

export const editOrganization = async (id, newOrg) => {
    return await putData(`organization?organization_id=${id}`, newOrg);
}

export const deleteOrganization = async (id) => {
    return await deleteData(`organization?organization_id=${id}`);
}