import { useState, useEffect } from "react";
import { connect } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { ThemeContext } from "./contexts/theme-context";
import { PermissionsContext } from "./contexts/permission-context";

import AppRoutes from "./AppRoutes";

const getModeFromLocalStorage = () => {
  const mode = localStorage.getItem("mode");
  if (mode) {
    return mode === "light" ? "light" : "dark";
  }
  return "light";
};

function App({ userPermissions }) {
  const [mode, setMode] = useState(getModeFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("mode", mode);
  }, [mode]);

  const darkTheme = {
    palette: {
      mode: "dark",
      primary: {
        main: "#22afef",
        dark: "#0b5d97",
        light: "#85d7f6",
      },
      secondary: {
        main: "#5635ba",
        dark: "#201b9c",
        light: "#b19ddf",
      },
      background: {
        default: "#4F545C",
        paper: "#333333",
        dark: "#000",
        custom: "#292929",
      },
      text: {
        primary: "#fff",
      },
    },
    overrides: {
      MuiAppBar: {
        colorInherit: {
          backgroundColor: "#689f38",
          color: "#fff",
        },
      },
    },
    typography: {
      fontFamily: "Cairo",
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
    },
  };

  const lightTheme = {
    palette: {
      mode: "light",
      primary: {
        main: "#22afef",
        dark: "#0b5d97",
        light: "#85d7f6",
      },
      secondary: {
        main: "#5635ba",
        dark: "#201b9c",
        light: "#b19ddf",
      },
      background: {
        default: "#fafafa",
        paper: "#fff",
        dark: "#fafafa",
      },
      text: {
        primary: "#000",
      },
      contrastThreshold: 3,
    },
    overrides: {
      MuiAppBar: {
        colorInherit: {
          backgroundColor: "#689f38",
          color: "#fff",
        },
      },
    },
    typography: {
      fontFamily: "Cairo",
      fontWeightLight: 600,
      fontWeightRegular: 600,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      body1: {
        fontWeight: 600,
      },
    },
  };

  const theme = createTheme(mode === "light" ? lightTheme : darkTheme);

  return (
    <ThemeContext.Provider value={{ mode, changeMode: setMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PermissionsContext.Provider value={{ userPermissions, setUserPermissions: () => {} }}>
          <AppRoutes />
        </PermissionsContext.Provider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

const mapStateToProps = (state) => ({
  userPermissions: state.user.permissions,
});

export default connect(mapStateToProps, {})(App);
