import { useEffect, useState } from "react";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";

import InfoTableDataHandler from "components/handlers/InfotableDataHandler";
import InfoTable from "../tables/InfoTable";
import { RoleDialog, ConfirmDialog } from "../dialogs";

import { hasPermission } from "../../utils/permissions";
import { role } from "../../actions";
import { withPermissions } from "../../hocs";
import { ADMIN_ROLES_READ, ADMIN_ROLES_WRITE } from "../../constants";
import PageHeader from "../headers/PageHeader";

function AdminRoleView({
  roles,
  message,
  isLoading,
  errors,
  getRoles,
  deleteRole,
}) {
  const [openDelete, setOpenDelete] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [showRoleDialog, setShowRoleDialog] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setCurrentRole(null);
  };

  const handleDeleteSubmit = () => {
    deleteRole(currentRole.id);
    handleDeleteClose();
  };

  const CreateRole = withPermissions(
    () => (
      <Button
        variant="outlined"
        sx={{
          fontWeight: 600,
        }}
        onClick={() => setShowRoleDialog(true)}
      >
        Crear Rol
      </Button>
    ),
    [ADMIN_ROLES_WRITE],
    false
  );

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Nombre", width: 150, flex: 1 },
    { field: "description", headerName: "Descripción", width: 300, flex: 1 },
    {
      field: "permissions",
      headerName: "Permisos Asociados",
      width: 100,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Fecha de Creación",
      width: 200,
      flex: 1,
    },
    {
      field: "updated_at",
      headerName: "Fecha de Actualización",
      width: 200,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      hide: !hasPermission(ADMIN_ROLES_WRITE),
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon color="primary"/>}
            onClick={() => {
              setFormValues({
                id: params.row.id,
                data: {
                  name: params.row.name,
                  description: params.row.description,
                  permissions: roles.find((role) => role.id === params.row.id)
                    .permissions,
                },
              });
              setShowRoleDialog(true);
            }}
            label="Editar Rol"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            onClick={() => {
              setCurrentRole(params.row);
              setOpenDelete(true);
            }}
            label="Eliminar Rol"
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  return (
    <div style={{ marginLeft: "325px", marginRight: "2%" }}>
      <RoleDialog
        open={showRoleDialog}
        setOpen={setShowRoleDialog}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <ConfirmDialog
        open={openDelete}
        handleClose={handleDeleteClose}
        handleSubmit={handleDeleteSubmit}
      >
        ¿Está seguro que desea eliminar el rol {currentRole && currentRole.name}?,
        esta acción es irreversible.
      </ConfirmDialog>

      <PageHeader title="Roles" children={<CreateRole />} />

      <h3>Lista de Roles registrados en Amadeus</h3>
      <Grid container>
        <InfoTableDataHandler
          isLoading={isLoading}
          error={errors}
          errorMessage={message}
          noData={!roles.length}
          noDataMessage="No hay roles registrados"
        >
          <InfoTable
            rows={roles.map((role) => {
              return {
                id: role.id,
                name: role.name,
                description: role.description,
                permissions: role.permissions.length,
                created_at: new Date(role.created_at),
                updated_at: new Date(role.updated_at),
              };
            })}
            columns={columns}
          />
        </InfoTableDataHandler>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    roles: state.role.data,
    message: state.role.message,
    errors: state.role.errors,
    isLoading: state.role.isLoading,
  };
};

const mapActionToProps = {
  getRoles: role.getRoles,
  deleteRole: role.deleteRole,
};

export default withPermissions(
  connect(mapStateToProps, mapActionToProps)(AdminRoleView),
  [ADMIN_ROLES_READ, ADMIN_ROLES_WRITE]
);
