import { useState } from "react";
import { connect } from "react-redux";

import FormDialog from "./FormDialog";
import { ConfirmDialog } from "./";
import { client } from "../../actions";
import AppEditForm from "components/forms/AppForm/edit";

function AppViewInfo({
  error,
  message,
  client,
  open,
  setOpen,
  refreshClientSecret,
  updateClient,
  scopes
}) {
  const [refreshSecret, setRefresh] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);

  const handleRefreshClose = () => {
    setRefresh(false);
  };

  const handleRefreshSubmit = () => {
    refreshClientSecret(client.id);
    handleRefreshClose();
  };

  const handleCancelSubmit = () => {
    setUpdateForm(false);
  };

  const handleClose = () => {
    handleCancelSubmit();
    if (!updateForm) {
      setOpen(false);
    }
  };

  const updateSubmit = (data) => {
    updateClient(client.id, data);
    handleCancelSubmit();
  };

  const onSubmit = (data) => {
    return updateForm
      ? updateSubmit(data)
      : setUpdateForm(true);
  };


  return (<>
    <ConfirmDialog
      open={refreshSecret}
      handleClose={handleRefreshClose}
      handleSubmit={handleRefreshSubmit}
    >
      ¿Está seguro que desea actualizar el client secret de la aplicación {client?.client_name}?,
      no podrá recuperar el client secret actual.
    </ConfirmDialog>
    <FormDialog
      open={open}
      submit={onSubmit}
      title={"Información de la Aplicación"}
      description={""}
      form={
        <AppEditForm
          values={client}
          scopes={scopes}
          submit={onSubmit}
          handleClose={handleClose}
          disableButton={!updateForm}
          submitButtonText={
            updateForm ? "Guardar Información" : "Editar Información"
          }
          cancelButtonText={
            updateForm ? "Cancelar" : "Cerrar"
          }
          message={message}
          error={error}
          setRefresh={setRefresh}
        />
      }
    />
  </>);
}

const mapStateToProps = (state, ownProps) => {
  return {
    error: state.client.errors,
    isLoading: state.client.isLoading,
    message: state.client.message,
    client: ownProps.client,
    open: ownProps.open,
    setOpen: ownProps.setOpen,
    scopes: ownProps.scopes,
  };
};

const mapActionToProps = {
  refreshClientSecret: client.refreshClientSecret,
  updateClient: client.updateClient,
};

export default connect(mapStateToProps, mapActionToProps)(AppViewInfo);
