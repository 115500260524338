import {
  CLIENT_GET_FAILED,
  CLIENT_GET_LOADING,
  CLIENT_GET_SUCCESS,
  CLIENT_CREATE_FAILED,
  CLIENT_CREATE_LOADING,
  CLIENT_CREATE_SUCCESS,
  CLIENT_UPDATE_LOADING,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAILED,
  CLIENT_DELETE_FAILED,
  CLIENT_DELETE_LOADING,
  CLIENT_DELETE_SUCCESS,
  CLIENT_RESET_MESSAGES,
  CLIENT_REFRESH_SECRET_LOADING,
  CLIENT_REFRESH_SECRET_SUCCESS,
  CLIENT_REFRESH_SECRET_FAILED,
} from "../actions/types";

const initialState = {
  isLoading: false,
  errors: false,
  message: null,
  data: [],
};

export default function client(state = initialState, action) {
  switch (action.type) {
    case CLIENT_RESET_MESSAGES:
      return {
        ...state,
        message: null,
        errors: false,
        isLoading: false,
      };
    case CLIENT_GET_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Obteniendo aplicaciones registradas",
      };
    case CLIENT_CREATE_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Creando aplicación",
      };
    case CLIENT_UPDATE_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Actualizando aplicación",
      }
    case CLIENT_DELETE_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Eliminando aplicación",
      };
    case CLIENT_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: action.payload.clients,
      };
    case CLIENT_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: [...state.data, action.payload.client],
      };
    case CLIENT_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: state.data.map((client) =>
          client.id === action.payload.client.id
            ? action.payload.client
            : client
        )
      }
    case CLIENT_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: state.data.filter(
          (client) => client.id !== action.payload.id
        )
      };
    case CLIENT_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload.message,
        data: [],
      };
    case CLIENT_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      };
    case CLIENT_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      }
    case CLIENT_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      };
    case CLIENT_REFRESH_SECRET_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Actualizando Client Secret...",
      }
    case CLIENT_REFRESH_SECRET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: state.data.map((client) =>
          client.id === action.payload.client.id
            ? action.payload.client
            : client
        )
      }
    case CLIENT_REFRESH_SECRET_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      }
    default:
      return { ...state };
  }
}
