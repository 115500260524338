import {
  RESOURCE_SERVER_CREATE_FAILED,
  RESOURCE_SERVER_CREATE_LOADING,
  RESOURCE_SERVER_CREATE_SUCCESS,
  RESOURCE_SERVER_GET_FAILED,
  RESOURCE_SERVER_GET_LOADING,
  RESOURCE_SERVER_GET_SUCCESS,
  RESOURCE_SERVER_DELETE_FAILED,
  RESOURCE_SERVER_DELETE_LOADING,
  RESOURCE_SERVER_DELETE_SUCCESS,
  RESOURCE_SERVER_RESET_MESSAGES,
  RESOURCE_SERVER_EDIT_LOADING,
  RESOURCE_SERVER_EDIT_SUCCESS,
  RESOURCE_SERVER_EDIT_FAILED,
} from "../actions/types";

const initialState = {
  isLoading: false,
  errors: false,
  message: null,
  data: [],
};

export default function rserver(state = initialState, action) {
  switch (action.type) {
    case RESOURCE_SERVER_RESET_MESSAGES:
      return {
        ...state,
        message: null,
        isLoading: false,
        errors: false,
      };
    case RESOURCE_SERVER_GET_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Obteniendo servidores registrados",
      };
    case RESOURCE_SERVER_CREATE_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Creando servidor...",
      };
    case RESOURCE_SERVER_EDIT_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Editando servidor de recursos...",
      };
    case RESOURCE_SERVER_DELETE_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Eliminando resource server",
      };
    case RESOURCE_SERVER_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: action.payload.rservers,
      };
    case RESOURCE_SERVER_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: [...state.data, action.payload.rserver],
      };
    case RESOURCE_SERVER_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: state.data.map((rserver) =>
          rserver.id === action.payload.rserver.id
            ? action.payload.rserver
            : rserver
        ),
      };
    case RESOURCE_SERVER_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: state.data.filter(
          (rserver) => rserver.id !== action.payload.id
        ),
      };
    case RESOURCE_SERVER_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
        data: [],
      };
    case RESOURCE_SERVER_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      };
    case RESOURCE_SERVER_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      };
    case RESOURCE_SERVER_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      };
    default:
      return { ...state };
  }
}
