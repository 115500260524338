import axios from "axios";
import * as userAPI from "../api/user";
import { displayAlert } from "./display_alert";
import { changeTokenStatus } from "./token";
import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  USER_GET_LOADING,
  USER_GET_SUCCESS,
  USER_GET_FAILED,
  USER_CREATE_LOADING,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILED,
  USER_UPDATE_LOADING,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILED,
  USER_DELETE_LOADING,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED,
  USER_RESET_MESSAGES,
  LOGOUT_SUCCESS,
  LOGIN_DEFAULT,
} from "./types";

const loginLoading = () => {
  return {
    type: LOGIN_LOADING,
  };
};

const loginDefault = () => {
  return {
    type: LOGIN_DEFAULT,
  };
};

const loginSuccess = (username, token, token_type, exp, roles, permissions) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      username,
      token,
      token_type,
      exp,
      roles,
      permissions,
    },
  };
};

export const loginFailed = (message) => {
  return {
    type: LOGIN_FAILED,
    payload: message,
  };
};

const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const userGetLoading = () => {
  return {
    type: USER_GET_LOADING,
  };
};

const userCreateLoading = () => {
  return {
    type: USER_CREATE_LOADING,
  };
};

const userUpdateLoading = () => {
  return {
    type: USER_UPDATE_LOADING,
  };
};

const userDeleteLoading = () => {
  return {
    type: USER_DELETE_LOADING,
  };
};

const userGetSuccess = (users, message) => {
  return {
    type: USER_GET_SUCCESS,
    payload: { message, users },
  };
};

const userCreateSuccess = (user, message) => {
  return {
    type: USER_CREATE_SUCCESS,
    payload: { message, user },
  };
};

const userUpdateSuccess = (user, message) => {
  return {
    type: USER_UPDATE_SUCCESS,
    payload: { message, user },
  };
};

const userDeleteSuccess = (id, message) => {
  return {
    type: USER_DELETE_SUCCESS,
    payload: { id, message },
  };
};

export const userGetFailed = (message) => {
  return {
    type: USER_GET_FAILED,
    payload: message,
  };
};

export const userCreateFailed = (message) => {
  return {
    type: USER_CREATE_FAILED,
    payload: message,
  };
};

export const userUpdateFailed = (message) => {
  return {
    type: USER_UPDATE_FAILED,
    payload: message,
  };
};

export const userDeleteFailed = (message) => {
  return {
    type: USER_DELETE_FAILED,
    payload: message,
  };
};

export const userResetMessages = () => {
  return {
    type: USER_RESET_MESSAGES,
  };
};

export const getUsers = () => {
  return async (dispatch) => {
    dispatch(userGetLoading());

    const { status, data, message } = await userAPI.getUsers();

    if (status) {
      dispatch(userGetSuccess(data.users, message));
    } else {
      dispatch(userGetFailed(message));
    }
  };
};

export const createUser = (user) => {
  return async (dispatch) => {
    dispatch(userCreateLoading());

    user.roles = user.roles.map(rol => rol.id);

    const { status, data, message } = await userAPI.createUser(user);

    if (status) {
      dispatch(userCreateSuccess(data, message));
      dispatch(displayAlert(`Usuario ${user.username} creado con éxito`));
    } else {
      dispatch(userCreateFailed(message));
    }
    setTimeout(() => {
      dispatch(userResetMessages());
    }, 2000);
  };
};

export const updateUser = (id, user) => {
  return async (dispatch) => {
    dispatch(userUpdateLoading());

    user.roles = user.roles.map(rol => rol.id);

    const { status, data, message } = await userAPI.updateUser(id, user);

    if (status) {
      dispatch(userUpdateSuccess(data, message));
      dispatch(displayAlert(`Usuario ${user.username} actualizado con éxito`));
    } else {
      dispatch(userUpdateFailed(message));
    }

    setTimeout(() => {
      dispatch(userResetMessages());
    }, 2000);
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch(userDeleteLoading());

    const { status, message } = await userAPI.deleteUser(id);

    if (status) {
      dispatch(userDeleteSuccess(id, message));
      dispatch(displayAlert(`Usuario eliminado con éxito`));
    } else {
      dispatch(userDeleteFailed(message));
    }
    
    setTimeout(() => {
      dispatch(userResetMessages());
    }, 2000);
  };
};

export const login = (data) => async (dispatch) => {
  const url = "/api/login";
  const config = { headers: { "content-type": "application/json" } };
  dispatch(loginLoading());

  try {
    const response = await axios.post(url, data, config);
    if (response.status === 200) {
      const data = response.data;
      const token = data.access_token;
      const token_type = data.token_type;
      const exp = data.exp;
      const roles = data.roles;
      const permissions = data.permissions;
      dispatch(changeTokenStatus(false));
      dispatch(loginSuccess(data.username, token, token_type, exp, roles, permissions));
    } else {
      dispatch(loginFailed(response.data.message));
    }
  } catch (error) {
    const message =
      error.response.status === 400
        ? error.response.data.error
        : "[ERROR] Hubo un problema en el servidor.";
    dispatch(loginFailed(message));
  }
};

export const toDefault = () => {
  return (dispatch) => {
    dispatch(loginDefault());
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutSuccess());
  };
};
