import { combineReducers } from "redux";

import user from './user';
import permission from "./permissions";
import role from "./role";
import client from "./client";
import displayAlert from "./display_alert";
import organization from "./organization";
import get_users from "./get_users";
import isToken from "./token";
import rserver from "./rserver";
import profile from "./profile";

const rootReducer = combineReducers({
    user,
    permission,
    role,
    client,
    organization,
    displayAlert,
    get_users,
    isToken,
    rserver,
    profile
});

export default rootReducer;
