import { useEffect } from "react";
import { connect } from "react-redux";

import FormDialog from "./FormDialog";
import RoleForm from "../forms/RoleForm";

import { permission, role } from "../../actions";

function RoleDialog({
  open,
  setOpen,
  close,
  permissions,
  isLoading,
  message, 
  error, 
  getPermissions,
  createRole,
  editRole,
  formValues,
  setFormValues,
}) {
  const handleClose = () => {
    setFormValues(null);
    setOpen(false);
  }

  const onSubmit = (data) => {
    return !formValues
      ? createRole(data)
      : editRole(formValues.id, data);
  };

  useEffect(() => {
    if (close) handleClose();
  }, [close]);

  useEffect(() => {
    if (open) getPermissions();
  }, [open]);

  return (
    <FormDialog
      open={open}
      title={
        formValues
          ? `Editar Rol: #${formValues.id} ${formValues.data.name}`
          : "Crear Rol"
      }
      description={
        <span>
          Estás apunto de {!formValues ? "crear" : "editar"} un rol en la aplicacion. <br />
          Por favor ingresa los datos solicitados a continuación.
        </span>
      }
      showMessage={error && !!message}
      errorMessage={message}
      form={
        <RoleForm
          values={formValues?.data}
          submit={onSubmit}
          handleClose={handleClose}
          disableButton={isLoading || (message && message !== "OK")}
          permissions={permissions}
          submitButtonText={
            !formValues ? "Crear Rol" : "Editar Rol"
          }
        />
      }
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.permission.data,
    close: state.displayAlert.display,
    error: state.role.errors,
    isLoading: state.role.isLoading,
    open: ownProps.open,
    setOpen: ownProps.setOpen,
    message: state.role.message,
    formValues: ownProps.formValues,
    setFormValues: ownProps.setFormValues,
  };
};

const mapActionToProps = {
  getPermissions: permission.getPermissions,
  createRole: role.createRole,
  editRole: role.editRole,
};

export default connect(mapStateToProps, mapActionToProps)(RoleDialog);
