import { useEffect } from "react";
import { connect } from "react-redux";

import UserForm from "components/forms/UserForm/";
import FormDialog from "./FormDialog";

import { role } from "../../actions";
import { user } from "../../actions";

function UserDialog({
  open,
  close,
  setOpen,
  isLoading,
  message,
  error,
  createUser,
  editUser,
  rolesData,
  getRoles,
  formValues,
  setFormValues,
}) {
  const handleClose = () => {
    setFormValues(null);
    setOpen(false);
  };

  const onSubmit = (data) => {
    return !formValues
      ? createUser(data)
      : editUser(formValues.id, data);
  }

  useEffect(() => {
    if (close) handleClose();
  }, [close]);

  useEffect(() => {
    if (open) {
      getRoles();
    }
  }, [open, getRoles]);

  return (
    <FormDialog
      open={open}
      title={
        formValues
          ? `Editar Usuario: #${formValues.id} ${formValues.data.username}`
          : "Crear Usuario"
      }
      description={
        <span>
          Estás apunto de {!formValues ? "crear" : "editar"} un usuario en Amadeus. <br />
          Por favor ingresa los datos solicitados a continuación.
        </span>
      }
      showMessage={error && !!message}
      errorMessage={message}
      form={
        <UserForm
          isEdit={!!formValues}
          values={formValues?.data}
          roles={rolesData}
          submit={onSubmit}
          handleClose={handleClose}
          disableButton={isLoading || (message && message !== "OK")}
          submitButtonText={
            !formValues ? "Crear Usuario" : "Editar Usuario"
          }
        />
      }
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    open: ownProps.open,
    close: state.displayAlert.display,
    isLoading: state.get_users.isLoading,
    message: state.get_users.message,
    error: state.get_users.errors,
    rolesData: state.role.data,
    setOpen: ownProps.setOpen,
    formValues: ownProps.formValues,
    setFormValues: ownProps.setFormValues,
  };
};

const mapActionToProps = {
  getRoles: role.getRoles,
  createUser: user.createUser,
  editUser: user.updateUser,
};

export default connect(mapStateToProps, mapActionToProps)(UserDialog);
