/**
 * Valida que el texto tenga un tamaño máximo de `maxSize` carácteres
 *
 * Retorna un mensaje de error si no cumple la condición, de lo contrario retorna `null`.
 * @param {string} value Valor del campo a validar
 * @param {number} maxSize Máximo valor permitido
 * @returns string | null
 */
export const mustHaveMaxSize = (value, maxSize) => {
  return value.length > maxSize
    ? `El campo debe tener un máximo de ${maxSize} caracteres`
    : null;
};

/**
 * Valida que el texto tenga un tamaño mínimo de `minSize` carácteres
 *
 * Retorna un mensaje de error si no cumple la condición, de lo contrario retorna `null`.
 * @param {string} value Valor del campo a validar
 * @param {number} minSize Mínimo valor permitido
 * @returns string | null
 */
export const mustHaveMinSize = (value, minSize) => {
  return value.length < minSize
    ? `El campo debe tener un mínimo de ${minSize} caracteres`
    : null;
};

/**
 * Valida que el texto sea una URI válida,
 * Para esto debe cumplir las siguientes propiedades:
 *   - Debe ser http o https.
 *   - No debe contener query string.
 *
 * Retorna un mensaje de error si no cumple la condición, de lo contrario retorna `null`.
 * @param {string} value URI a validar
 * @returns string | null
 */
export const mustBeAValidUrl = (value) => {
  try {
    const url = new URL(value);
    if (url.protocol !== "http:" && url.protocol !== "https:") {
      return "El protocolo debe ser http o https";
    }
    if (url.search || url.hash) {
      return "La URL no debe contener query string ni hashes, debe ser absoluta";
    }
    return null;
  } catch {
    return "La URL no es válida";
  }
};

/**
 * Valida que el email ingresado sea válido
 * @param {string} value Email a validar
 * @returns string | null
 */
export const mustBeAValidEmail = (value) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(value) ? null : "El email no es válido";
};

/**
 * Valida que el texto solo sea alfabético.
 *
 * Retorna un mensaje de error si no cumple la condición, de lo contrario retorna `null`.
 * @param {string} value Valor a validar
 * @returns string | null
 */
export const mustBeAlphabetical = (value) => {
  return /^[a-zA-Z]+$/.test(value)
    ? null
    : "El campo debe contener solo letras";
};

/**
 * Valida que el texto sea un número
 * @param  {string} value Texto a validar
 * @returns string | null
 */
export const mustBeANumber = (value) => {
  return /^\d+$/.test(value) ? null : "El campo debe contener solo números";
};

/**
 * Valida que el arreglo entrante contenga solo números enteros positivos
 * @param {any[]} values Arreglo de valores
 * @param {boolean} withZero Si es `true` permite valores 0
 * @returns string | null
 */
export const mustContainsPositiveNumbers = (values, withZero = true) => {
  return values.every((value) => {
    return withZero ? value >= 0 : value > 0;
  })
    ? null
    : "El campo debe contener solo números enteros positivos (0 incluido si la opción está habilitada)";
};

/**
 * Valida que el permiso contenga el formato rserver:org:resource:level
 * @param  {string} value Permiso a validar
 * @returns string | null
 */
export const mustBeAValidPermission = (value) => {
  const regex =
    /^([a-zA-Z0-9-_]+):([a-zA-Z0-9-_]+):([a-zA-Z0-9-_]+):([a-zA-Z0-9-_]+)$/;
  return regex.test(value)
    ? null
    : "El permiso debe tener el formato rserver:org:resource:level";
};

/**
 * Valida que la contraseña ingresada sea segura según las reglas de seguridad.
 * @param  {string} value Contraseña a validar
 * @returns string | null
 */
export const mustBeSecurePassword = (value) => {
  const regex =
    /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[@#$\.])[\w\d@#$\.]{8,}$/;
  return regex.test(value)
    ? null
    : "La contraseña debe tener al menos 8 caracteres, incluyendo una mayúscula, una minúscula, un número y un caracter especial";
};

/**
 * Combina validaciones entregadas por el Usuario y
 * retorna el primer error encontrado o null si no hay errores.
 * @param  {string[]} validators Validadores evaluados
 * @returns {string | null} Mensaje de error o null si no hay errores
 */
export const composeValidators = (...validators) => {
  const error = validators.filter((error) => error !== null).pop();
  return error === undefined ? null : error;
};