export const LOCAL_STORAGE_USER_TOKEN = "userToken";
export const LOCAL_STORAGE_USERNAME = "username";
export const LOCAL_STORAGE_TOKEN_TYPE = "token_type";
export const LOCAL_STORAGE_EXP = "expiration";
export const LOCAL_STORAGE_ROLES = "roles";
export const LOCAL_STORAGE_PERMISSIONS = "permissions";

// Permisos para Administrador
export const ADMIN_USERS_READ = "amadeus:dualvision:users:read";
export const ADMIN_USERS_WRITE = "amadeus:dualvision:users:write";
export const ADMIN_ROLES_READ = "amadeus:dualvision:roles:read";
export const ADMIN_ROLES_WRITE = "amadeus:dualvision:roles:write";
export const ADMIN_PERMISSIONS_READ = "amadeus:dualvision:pers:read";
export const ADMIN_PERMISSIONS_WRITE = "amadeus:dualvision:pers:write";
export const ADMIN_ORGANIZATIONS_READ = "amadeus:dualvision:orgs:read";
export const ADMIN_ORGANIZATIONS_WRITE = "amadeus:dualvision:orgs:write";
export const ADMIN_RESOURCE_SERVERS_WRITE = "amadeus:dualvision:rservers:write";
export const ADMIN_RESOURCE_SERVERS_READ = "amadeus:dualvision:rservers:read";

// Permisos para Desarrollador
export const APPS_DEV_READ = "amadeus:dualvision:applications:read";
export const APPS_DEV_WRITE = "amadeus:dualvision:applications:write";
export const RESOURCE_SERVERS_DEV_READ = "amadeus:dualvision:rservers:read";