import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

import { DualVision } from "../../components";
import { LoginForm, validate, initialValues } from "../forms/LoginForm";
import { withValidation } from "../../hocs";

const LoginBox = (props) => {
  const Form = withValidation(LoginForm, { initialValues, validate });
  const theme = useTheme();

  const boxTheme = {
    p: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 2,
    backgroundColor: theme.palette.background.paper,
    maxWidth: "400px",
  };
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Box sx={boxTheme}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <DualVision />
        </Box>
        <Divider variant="middle" style={{ width: "100%" }} />
        <Form
          isLoading={props.isLoading}
          hasToDisableButton={props.hasToDisableButton}
          senderDataFn={props.senderDataFn}
        />
        {props.message && (
          <Box sx={{ mt: 2 }}>
            {
              <Alert severity="error" variant="filled">
                <AlertTitle>Error en la petición:</AlertTitle>
                {props.message}
              </Alert>
            }
          </Box>
        )}
        <Divider
          variant="middle"
          style={{ width: "100%", marginTop: "20px" }}
        />
      </Box>
    </Grid>
  );
};

export default LoginBox;
