import { getData, postData, putData, deleteData } from "./base";

export const getUsers = async () => {
    return await getData("user");
}

export const createUser = async (newUser) => {
    return await postData("user", newUser);
}

export const updateUser = async (id, updateUser) => {
    return await putData(`user?user_id= ${id}`, updateUser);
}

export const deleteUser = async (id) => {
    return await deleteData(`user?user_id=${id}`);
}