import axios from "./axios";

/**
 * 
 * Gestiona la actualización de datos en la API de DualVision
 * @param {String} url 
 * @param {*} data 
 * @returns 
 */
export const putData = async(url, data) => {
    return await axios.put(url, data);
}

/**
 * 
 * Gestiona la carga de datos en la API de DualVision
 * @param {String} url 
 * @param {*} data 
 * @returns 
 */
export const postData = async(url, data, params) => {
  return await axios.post(url, data, { params });
}


/**
 * Gestiona la obtención de datos en la API de DualVision
 * @param {string} url
 * @param {object} params
 */
export const getData = async(url, params) => {
  return await axios.get(url, { params });
}

/**
 * Gestiona la eliminación de datos en la API de DualVision
 * @param {string} url
 */
export const deleteData = async(url) => {
  return await axios.delete(url);
}

/**
 * Gestiona la actualización de datos de la API de DualVision, parcialmente
 * @param {string} url
 * @param {*} data
 * @returns
 */
export const patchData = async(url, data) => {
  return await axios.patch(url, data);
}