import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";

import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import BadgeIcon from "@mui/icons-material/Badge";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { connect } from "react-redux";
import { stringAvatar } from "../utils/avatar";
import { user } from "../actions";
import { profile } from "../actions";
import { ICON_PRIMARY_COLOR } from "../colorTheme";
import { ThemeButton } from "./buttons/ThemeButton";

function Navbar({ username, profile, drawerWidth, logout, getProfile }) {
  const [user, setUser] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getProfile()
  }, [getProfile])


  useEffect(() => {
    if (username) {
      setUser(username);
    }
  }, [username]);

  const handleLogout = () => {
    logout();
    navigate("/login", { replace: true });
  };
  return (
    <AppBar
      position="fixed"
      color="transparent"
      elevation={0}
      sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
    >
      <Toolbar>
        <div style={{marginLeft: "auto", marginRight: 0}}>
          <Grid direction="row" container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={4} onClick={handleClick}>
              {
                (!profile || Object.keys(profile).length === 0) ?
                  <Avatar {...stringAvatar(username)} />
                  :
                  profile.photo ?
                    <Avatar
                      alt={profile.name}
                      src={`data:image/png;base64,${profile.photo}`}
                    />
                    :
                    <Avatar {...stringAvatar(profile.name)} />
              }
            </Grid>
            <Grid item xs={4} onClick={handleClick}>
              <Typography component="span" >{user}</Typography>
            </Grid>
            <div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem component={Link} to="/profile">
                  <ListItemIcon >
                    <BadgeIcon sx={{ color: ICON_PRIMARY_COLOR }} />
                  </ListItemIcon>
                  <ListItemText>Mi Perfil</ListItemText>
                </MenuItem>

                <Divider />

                <MenuItem>
                  <ThemeButton />
                </MenuItem>

                <MenuItem onClick={() => handleLogout()}>
                  <ListItemIcon>
                    <LogoutIcon sx={{ color: ICON_PRIMARY_COLOR }} />
                  </ListItemIcon>
                  <ListItemText>Desconectar</ListItemText>

                </MenuItem>
              </Menu>
            </div>
          </Grid>
        </div>
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = (state) => ({
  username: state.user.username,
  profile: state.profile.data,

});

const mapActionsToProps = {
  logout: user.logout,
  getProfile: profile.getProfile,
};

export default connect(mapStateToProps, mapActionsToProps)(Navbar);
