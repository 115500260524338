import { getData, postData, putData, deleteData } from "./base";

export const getPermissions = async () => {
  return await getData("permission");
};

export const createPermission = async (newPermission) => {
  return await postData("permission", newPermission);
};

export const editPermission = async (id, newPermission) => {
  return await putData(`permission?permission_id=${id}`, newPermission);
};

export const deletePermission = async (id) => {
  return await deleteData(`permission?permission_id=${id}`);
};
