import { displayAlert } from "./display_alert";
import * as profileAPI from "../api/profile";

import {
  PROFILE_GET_LOADING,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAILED,
  PROFILE_CREATE_LOADING,
  PROFILE_CREATE_SUCCESS,
  PROFILE_CREATE_FAILED,
  PROFILE_RESET_MESSAGES,
} from "./types";

const profileGetLoading = () => {
  return {
    type: PROFILE_GET_LOADING,
  };
};

const profileCreateLoading = () => {
  return {
    type: PROFILE_CREATE_LOADING,
  };
};

const profileGetSuccess = (profile, message) => {
  return {
    type: PROFILE_GET_SUCCESS,
    payload: { profile, message },
  };
};

const profileCreateSuccess = (profile, message) => {
  return {
    type: PROFILE_CREATE_SUCCESS,
    payload: { profile, message },
  };
};

const profileGetFailed = (message) => {
  return {
    type: PROFILE_GET_FAILED,
    payload: message,
  };
};

const profileCreateFailed = (message) => {
  return {
    type: PROFILE_CREATE_FAILED,
    payload: message,
  };
};

const profileResetMessages = () => {
  return {
    type: PROFILE_RESET_MESSAGES,
  };
};

export const getProfile = () => {
  return async (dispatch) => {
    dispatch(profileGetLoading());

    const { status, data, message } = await profileAPI.getProfile();

    if (status) {
      dispatch(profileGetSuccess(data.profile, message));
    } else {
      dispatch(profileGetFailed(message));
    }

    dispatch(profileResetMessages());
  };
};

export const createProfile = (createData) => {
  return async (dispatch) => {
    dispatch(profileCreateLoading());
    
    const { status, data, message } = await profileAPI.createProfile(createData);
    
    if (status) {
      dispatch(profileCreateSuccess(data, message));
      dispatch(displayAlert(`Perfil de usuario actualizado con éxito.`));
    } else {
      dispatch(profileCreateFailed(message));
    }
    setTimeout(() => {
      dispatch(profileResetMessages());
    }, 2000);
  };
};
