import { useContext } from "react";

import { PermissionsContext } from "../contexts/permission-context";
import RedirectComponent from "../components/RedirectComponent";

/**
 * Dado un Componente y una lista de permisos, verifica que el usuario
 * tenga al menos uno de los permisos.
 * 
 * Si `redirect` está en true, entonces redirigirá a la página de /login
 * 
 * @param {React.FC} Component Componente a renderizar
 * @param {String[]} permissions Lista de permisos requeridos por el componente
 * @param {Boolean} redirect Si es true, redirigirá a la página de /login, si no, el componente no se renderizará
 * @returns {React.FC} Componente con el hook
 */
const withPermissions = (Component, permissions, redirect = true) => {
  const WrappedComponent = ({ ...props }) => {
    
    // TODO: Encontrar una forma más óptima de recuperar los permisos del usuario
    const { userPermissions, setUserPermissions } =
      useContext(PermissionsContext);

    // Si el usuario no está autenticado, se asume que no tiene permisos.
    if (!userPermissions) {
      return redirect && <RedirectComponent /> ;
    }

    // Si el usuario tiene permisos, verificamos que tenga al menos uno de los requeridos.
    const hasPermission =
      permissions.length === 0
        ? true
        : permissions.some((permission) =>
            userPermissions.includes(permission)
          );
    return hasPermission ? (
      <Component {...props} />
    ) : (
      redirect && <RedirectComponent />
    );
  };

  return WrappedComponent;
};

export default withPermissions;
