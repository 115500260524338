export const clientNameRules = {
  required: {
    value: true,
    message: "El nombre del cliente es obligatorio."
  },
  minLength: {
    value: 2,
    message: "El nombre del cliente debe tener como mínimo 2 carácteres."
  },
  maxLength: {
    value: 50,
    message: "El nombre del cliente no puede sobrepasar los 50 carácteres."
  }
}

export const clientDescriptionRules = {
  required: {
    value: true,
    message: "La descripción del cliente es obligatoria."
  },
  minLength: {
    value: 2,
    message: "El nombre del cliente debe tener como mínimo 2 carácteres."
  },
  maxLength: {
    value: 120,
    message: "El nombre del cliente no puede sobrepasar los 120 carácteres."
  }
}

function validURL(value) {
  try {
    const url = new URL(value);
    if (url.protocol !== "http:" && url.protocol !== "https:") {
      return "El protocolo debe ser http o https";
    }
    if (url.search || url.hash) {
      return "La URL no debe contener query string ni hashes, debe ser absoluta";
    }
    return true;
  } catch {
    return "La URL no es válida";
  }
}

export const clientUriRules = {
  required: {
    value: true,
    message: "La url del cliente es obligatoria."
  },
  validate: v => validURL(v)
}

export const redirectUrisRules = {
  required: {
    value: true,
    message: "Las uri de redirección son obligatorias."
  },
  validate: v => validURL(v)
}

export const grantTypesRules = {
  required: {
    value: true,
    message: "Los tipos de autorización son obligatorios."
  },
  validate: v => v === "authorization_code" ||  "Por el momento, solo Auth Code está permitido"
}

export const responseTypesRules = {
  required: {
    value: true,
    message: "Los tipos de respuesta son obligatorias."
  },
  validate: v => v === "code" ||  "Por el momento, solo Code está permitido"
}

export const tokenEndpointAuthMethodRules = {
  required: {
    value: true,
    message: "El método de autenticación es obligatorio."
  },
  validate: v => {
    return ["client_secret_post", "none"].includes(v) || "Solo puedes crear aplicaciones con client_secret_post o publicas (sin token auth)"
  }
}

export const scopesRules = {
  required: {
    value: true,
    message: "Los scopes son obligatorios."
  },
  validate: v => v.length > 0 || "Debes ingresar al menos un scope"
}

export const imageRules = {
  required: {
    value: true,
    message: "La imagen es obligatoria."
  },
}