import { useState } from "react";

import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

import * as permissions from "../../constants";
import { withPermissions } from "../../hocs";
import OrganizationMenu from "./OrganizationMenu";
import ResourceServerMenu from "./ResourceServerMenu";
import UserMenu from "./UserMenu";
import PermissionMenu from "./PermissionMenu";
import RoleMenu from "./RoleMenu";

function AdministrationMenu() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <VerifiedUserIcon />
        </ListItemIcon>
        <ListItemText primary={"Administración"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ ml: 2 }}>
          <OrganizationMenu />
          <ResourceServerMenu />
          <UserMenu />
          <PermissionMenu />
          <RoleMenu />
        </List>
      </Collapse>
    </List>
  );
}

export default withPermissions(
  AdministrationMenu,
  [
    permissions.ADMIN_ORGANIZATIONS_READ,
    permissions.ADMIN_ORGANIZATIONS_WRITE,
    permissions.ADMIN_PERMISSIONS_READ,
    permissions.ADMIN_PERMISSIONS_WRITE,
    permissions.ADMIN_ROLES_READ,
    permissions.ADMIN_ROLES_WRITE,
    permissions.ADMIN_USERS_READ,
    permissions.ADMIN_USERS_WRITE,
    permissions.ADMIN_RESOURCE_SERVERS_WRITE,
    permissions.ADMIN_RESOURCE_SERVERS_READ,
  ],
  false
);
