import { useState } from "react";

import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import ApplicationMenu from "./ApplicationMenu";
import { withPermissions } from "../../hocs";
import { APPS_DEV_READ, APPS_DEV_WRITE } from "../../constants";

function DeveloperMenu() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <ManageAccountsIcon />
        </ListItemIcon>
        <ListItemText primary={"Desarrollador"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ ml: 2 }}>
          <ApplicationMenu />
        </List>
      </Collapse>
    </List>
  );
}

export default withPermissions(DeveloperMenu, [APPS_DEV_READ, APPS_DEV_WRITE], false); 
