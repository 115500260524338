import { getData, postData } from "./base";


const getProfile = async () => {  
  return await getData("profile");
};

const createProfile  = async (data) => {
  const url = "profile";

  const form = new FormData();
  Object.keys(data).forEach(key => {
    form.append(key,data[key]);
  })
  
  const response = await postData(url,form);
  response.message = response.status ? "[OK] Perfil creado correctamente" : response.message;
  return response;
}

export { getProfile, createProfile };
