import { displayAlert } from "./display_alert";
import * as organizationAPI from "../api/organization";

import {
  ORGANIZATION_GET_LOADING,
  ORGANIZATION_GET_SUCCESS,
  ORGANIZATION_GET_FAILED,
  ORGANIZATION_CREATE_LOADING,
  ORGANIZATION_CREATE_SUCCESS,
  ORGANIZATION_CREATE_FAILED,
  ORGANIZATION_EDIT_LOADING,
  ORGANIZATION_EDIT_SUCCESS,
  ORGANIZATION_EDIT_FAILED,
  ORGANIZATION_DELETE_LOADING,
  ORGANIZATION_DELETE_SUCCESS,
  ORGANIZATION_DELETE_FAILED,
  ORGANIZATION_RESET_MESSAGES,
} from "./types";

const organizationResetMessages = () => {
  return {
    type: ORGANIZATION_RESET_MESSAGES,
  };
};

const organizationGetLoading = () => {
  return {
    type: ORGANIZATION_GET_LOADING,
  };
};

const organizationGetSuccess = (organizations, message) => {
  return {
    type: ORGANIZATION_GET_SUCCESS,
    payload: { message, organizations },
  };
};

export const organizationGetFailed = (message) => {
  return {
    type: ORGANIZATION_GET_FAILED,
    payload: message,
  };
};

const organizationCreateLoading = () => {
  return {
    type: ORGANIZATION_CREATE_LOADING,
  };
};

const organizationCreateSuccess = (organization, message) => {
  return {
    type: ORGANIZATION_CREATE_SUCCESS,
    payload: { message, organization },
  };
};

export const organizationCreateFailed = (message) => {
  return {
    type: ORGANIZATION_CREATE_FAILED,
    payload: message,
  };
};

const organizationEditLoading = () => {
  return {
    type: ORGANIZATION_EDIT_LOADING,
  };
};

const organizationEditSuccess = (organization, message) => {
  return {
    type: ORGANIZATION_EDIT_SUCCESS,
    payload: { message, organization },
  };
};

export const organizationEditFailed = (message) => {
  return {
    type: ORGANIZATION_EDIT_FAILED,
    payload: message,
  };
};

export const organizationDeleteLoading = () => {
  return {
    type: ORGANIZATION_DELETE_LOADING,
  };
};

export const organizationDeleteSuccess = (id, message) => {
  return {
    type: ORGANIZATION_DELETE_SUCCESS,
    payload: { id, message },
  };
};

export const organizationDeleteFailed = (message) => {
  return {
    type: ORGANIZATION_DELETE_FAILED,
    payload: message,
  };
};

export const getOrganizations = () => {
  return async (dispatch) => {
    dispatch(organizationGetLoading());
    const { status, data, message } = await organizationAPI.getOrganizations();

    if (status) {
      dispatch(
        organizationGetSuccess(data.organizations, message)
      );
    } else {
      dispatch(organizationGetFailed(message));
    }
    dispatch(organizationResetMessages());
  };
};

export const createOrganization = (newOrg) => {
  return async (dispatch) => {
    dispatch(organizationCreateLoading());
    
    const { status, data, message } = await organizationAPI.createOrganization(newOrg);

    if (status) {
      dispatch(
        organizationCreateSuccess(data, message)
      );
      dispatch(displayAlert(`Organización ${newOrg.name} creada con éxito`));
    } else {
      dispatch(organizationCreateFailed(message));
    }

    setTimeout(() => {
      dispatch(organizationResetMessages());
    }, 2000);
  };
};

export const editOrganization = (id, newOrg) => {
  return async (dispatch) => {
    dispatch(organizationEditLoading());
    
    const { status, data, message } = await organizationAPI.editOrganization(id, newOrg);

    if (status) {
      dispatch(
        organizationEditSuccess(data, message)
      );
      dispatch(displayAlert(`Organización ${newOrg.name} editada con éxito`));
    } else {
      dispatch(organizationEditFailed(message));
    }
    setTimeout(() => {
      dispatch(organizationResetMessages());
    }, 2000);
  };
}

export const deleteOrganization = (id) => {
  return async (dispatch) => {
    dispatch(organizationDeleteLoading());
    const { status, message } = await organizationAPI.deleteOrganization(id);
    
    if (status) {
      dispatch(
        organizationDeleteSuccess(id, message)
      );
      dispatch(displayAlert(`Organización eliminada con éxito`));
    } else {
      dispatch(organizationDeleteFailed(message));
    }

    setTimeout(() => {
      dispatch(organizationResetMessages());
    }, 2000);
  };
}
