import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";

import {
  ADMIN_ORGANIZATIONS_READ,
  ADMIN_ORGANIZATIONS_WRITE,
} from "../../constants";
import { withPermissions } from "../../hocs";

const OrganizationMenu = () => {
  return (
    <ListItem button component={Link} to="/organizations">
      <ListItemIcon>
        <CorporateFareIcon />
      </ListItemIcon>
      <ListItemText primary={"Organizaciones"} />
    </ListItem>
  );
};

export default withPermissions(
  OrganizationMenu,
  [ADMIN_ORGANIZATIONS_READ, ADMIN_ORGANIZATIONS_WRITE],
  false
);
