import { displayAlert } from "./display_alert";
import * as roleAPI from "../api/role";

import {
  ROLE_GET_LOADING,
  ROLE_GET_SUCCESS,
  ROLE_GET_FAILED,
  ROLE_RESET_MESSAGES,
  ROLE_CREATE_FAILED,
  ROLE_CREATE_LOADING,
  ROLE_CREATE_SUCCESS,
  ROLE_EDIT_FAILED,
  ROLE_EDIT_LOADING,
  ROLE_EDIT_SUCCESS,
  ROLE_DELETE_FAILED,
  ROLE_DELETE_LOADING,
  ROLE_DELETE_SUCCESS,
} from "./types";

const roleGetLoading = () => {
  return {
    type: ROLE_GET_LOADING,
  };
};

const roleCreateLoading = () => {
  return {
    type: ROLE_CREATE_LOADING,
  };
};

const roleEditLoading = () => {
  return {
    type: ROLE_EDIT_LOADING,
  };
};

const roleDeleteLoading = () => {
  return {
    type: ROLE_DELETE_LOADING,
  };
};

const roleGetSuccess = (roles, message) => {
  return {
    type: ROLE_GET_SUCCESS,
    payload: { message, roles },
  };
};

const roleCreateSuccess = (role, message) => {
  return {
    type: ROLE_CREATE_SUCCESS,
    payload: { message, role },
  };
};

const roleEditSuccess = (role, message) => {
  return {
    type: ROLE_EDIT_SUCCESS,
    payload: { message, role },
  };
};

const roleDeleteSuccess = (id, message) => {
  return {
    type: ROLE_DELETE_SUCCESS,
    payload: { id, message },
  };
};

export const roleGetFailed = (message) => {
  return {
    type: ROLE_GET_FAILED,
    payload: message,
  };
};

export const roleCreateFailed = (message) => {
  return {
    type: ROLE_CREATE_FAILED,
    payload: message,
  };
};

export const roleEditFailed = (role, message) => {
  return {
    type: ROLE_EDIT_FAILED,
    payload: message,
  };
};

export const roleDeleteFailed = (message) => {
  return {
    type: ROLE_DELETE_FAILED,
    payload: message,
  };
};

export const roleResetMessages = () => {
  return {
    type: ROLE_RESET_MESSAGES,
  };
};

export const getRoles = () => {
  return async (dispatch) => {
    dispatch(roleGetLoading());
    
    const { status, data, message } = await roleAPI.getRoles();

    if (status) {
      dispatch(roleGetSuccess(data.roles, message));
    } else {
      dispatch(roleGetFailed(message));
    }
  };
};

export const createRole = (newRole) => {
  return async (dispatch) => {
    dispatch(roleCreateLoading());

    newRole.permissions = newRole.permissions.map(per => per.id);

    const { status, data, message } = await roleAPI.createRole(newRole);

    if (status) {
      dispatch(roleCreateSuccess(data, message));
      dispatch(displayAlert(`Rol ${newRole.name} creado con éxito`));
    } else {
      dispatch(roleCreateFailed(message));
    }

    setTimeout(() => {
      dispatch(roleResetMessages());
    }, 2000);
  };
};

export const editRole = (id, newRole) => {
  return async (dispatch) => {
    dispatch(roleEditLoading());

    newRole.permissions = newRole.permissions.map(per => per.id);

    const { status, data, message } = await roleAPI.editRole(id, newRole);

    if (status) {
      dispatch(roleEditSuccess(data, message));
      dispatch(displayAlert(`Rol ${newRole.name} editado con éxito`));
    } else {
      dispatch(roleEditFailed(message));
    }

    setTimeout(() => {
      dispatch(roleResetMessages());
    }, 2000);
  };
}

export const deleteRole = (id) => {
  return async (dispatch) => {
    dispatch(roleDeleteLoading());
    const { status, message } = await roleAPI.deleteRole(id);

    if (status) {
      dispatch(roleDeleteSuccess(id,message));
      dispatch(displayAlert(`Rol eliminado con éxito`));
    } else {
      dispatch(roleDeleteFailed(message));
    }
    
    setTimeout(() => {
      dispatch(roleResetMessages());
    }, 2000);
  };
}
