import { useState, useEffect } from "react";
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import "./style.css";
import logo from "../../images/logo_dv.png";

const font = "'Cairo', sans-serif";

export default function DualVision({ size }) {
  const [realSize, setRealSize] = useState(40);

  useEffect(() => {
    if (size) {
      setRealSize(size);
    }
  }, [size]);

  const theme = createTheme({
    typography: {
      fontFamily: font,
      fontSize: realSize,
      fontWeightLight: 200,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Typography>
        <img
          src={logo}
          width={64}
          style={{ verticalAlign: "middle", marginRight: "5px" }}
        />
        <span color="primary">Ama</span>deus
      </Typography>
    </ThemeProvider>
  );
}
