import { 
    DISPLAY_ALERT, 
    DISPLAY_ALERT_FADE,
    DISPLAY_ALERT_CLEAN
} from "../actions/types";

const initialState = {
    message: "",
    display: false,
}

export default function displayAlert(state = initialState, action) {
    switch (action.type) {
        case DISPLAY_ALERT:
            return {
                message: action.payload,
                display: true
            }
        case DISPLAY_ALERT_FADE:
            return {
                ...state,
                display: false,
            }
        case DISPLAY_ALERT_CLEAN:
            return {
                message: "",
                display: false
            }
        default:
            return { ...state }
    }
}
