import { useForm, Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { mustBeAValidUrl } from "utils/validators";

const ResourceServerForm = ({
  values,
  submit,
  handleClose,
  disableButton,
  submitButtonText,
}) => {
  const initialValues = {
    server_name: "",
    server_description: "",
    server_uri: "",
    scope: "",
  };

  const { control, handleSubmit } = useForm({
    defaultValues: values ?? initialValues,
  });

  const onSubmit = (data) => {
    submit(data);
  };

  const nameRules = {
    required: { value: true, message: "El nombre es obligatorio." },
    minLength: {
      value: 3,
      message: "El nombre debe tener como mínimo 3 carácteres.",
    },
    maxLength: {
      value: 50,
      message: "El nombre no puede sobrepasar los 50 carácteres.",
    },
  };

  const descriptionRules = {
    required: { value: true, message: "La descripción es obligatoria." },
    minLength: {
      value: 3,
      message: "La descripción debe tener como mínimo 3 carácteres.",
    },
    maxLength: {
      value: 255,
      message: "La descripción no puede sobrepasar los 255 carácteres.",
    },
  };

  const uriRules = {
    required: {
      value: true,
      message: "La URL del Servidor de Recursos es obligatoria.",
    },
    validate: (value) => {
      const error = mustBeAValidUrl(value);
      return !error ? true : error
    },
  };

  const scopeRules = {
    required: {
      value: true,
      message: "El scope es obligatorio.",
    },
    minLength: {
      value: 3,
      message: "El scope debe tener como mínimo 3 carácteres.",
    },
    maxLength: {
      value: 32,
      message: "El scope no puede sobrepasar los 32 carácteres.",
    },
    pattern: {
      value: /[A-Za-z]+/,
      message: "El scope debe contener solo letras.",
    }
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Controller
          name="server_name"
          rules={nameRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                label="Nombre del Servidor de Recursos"
                variant="standard"
                color="primary"
                helperText={error && error.message}
                sx={{ mb: 2 }}
              />
            );
          }}
        />
        <Controller
          name="server_description"
          rules={descriptionRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                label="Descripción del Servidor de Recursos"
                variant="standard"
                color="primary"
                multiline
                rows={3}
                helperText={error && error.message}
                sx={{ mb: 2 }}
              />
            );
          }}
        />
        <Controller
          name="server_uri"
          rules={uriRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                label="URL del Servidor de Recursos"
                variant="standard"
                color="primary"
                helperText={error && error.message}
                sx={{ mb: 2 }}
              />
            );
          }}
        />
        <Controller
          name="scope"
          rules={scopeRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                label="Scope a definir"
                variant="standard"
                color="primary"
                helperText={error && error.message}
              />
            );
          }}
        />
        <Box sx={{ mt: 2, textAlign: "end" }}>
          <Button onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" disabled={disableButton}>
            {submitButtonText ?? "Enviar"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ResourceServerForm;
