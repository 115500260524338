import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

const FormDialog = (props) => {
  const theme = useTheme();

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.dark,
        },
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle color="primary">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          <Typography component={'span'}>{props.description}</Typography>
        </DialogContentText>
        {props.form}
        {props.showMessage ? (
          <Fade in={props.showMessage} timeout={500}>
            <Box sx={{ mt: 2 }}>
              {
                <Alert severity={"error"} variant="filled">
                  <AlertTitle> {"Error en la petición:"}</AlertTitle>
                  {props.errorMessage}
                </Alert>
              }
            </Box>
          </Fade>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;
