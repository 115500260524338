import { useEffect } from "react";
import { connect } from "react-redux";

import FormDialog from "./FormDialog";
import PermissionForm from "components/forms/PermissionForm";

import { organization, permission } from "../../actions";

function PermissionDialog({
  open,
  close,
  setOpen,
  isLoading,
  message,
  error,
  organizations,
  createPermission,
  editPermission,
  getOrganizations,
  formValues,
  setFormValues,
}) {
  const handleClose = () => {
    setFormValues(null);
    setOpen(false);
  };

  const onSubmit = (data) => {
    return !formValues
      ? createPermission(data)
      : editPermission(formValues.id, data);
  }

  useEffect(() => {
    if (close) handleClose();
  }, [close]);

  useEffect(() => {
    if (open) {
      getOrganizations();
    }
  }, [open]);

  return (
    <FormDialog
      open={open}
      title={
        !formValues
          ? "Crear Permiso"
          : `Editar Permiso: #${formValues.id} ${formValues.data.name}`
      }
      description={
        <span>
          Estás apunto de {!formValues ? "crear" : "editar"} un permiso en Amadeus. <br />
          Por favor ingresa los datos solicitados a continuación.
        </span>
      }
      showMessage={error && !!message}
      errorMessage={message}
      form={
        <PermissionForm
          isEdit={!!formValues}
          values={formValues?.data}
          submit={onSubmit}
          handleClose={handleClose}
          disableButton={isLoading || (message && message !== "OK")}
          organizations={organizations}
          submitButtonText={
            !formValues ? "Crear Permiso" : "Editar Permiso"
          }
        />
      }
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    organizations: state.organization.data,
    close: state.displayAlert.display,
    isLoading: state.permission.isLoading,
    message: state.permission.message,
    error: state.permission.errors,
    open: ownProps.open,
    setOpen: ownProps.setOpen,
    formValues: ownProps.formValues,
    setFormValues: ownProps.setFormValues,
  };
};

const mapActionToProps = {
  getOrganizations: organization.getOrganizations,
  createPermission: permission.createPermission,
  editPermission: permission.editPermission,
};

export default connect(mapStateToProps, mapActionToProps)(PermissionDialog);
