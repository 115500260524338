import { getData, postData, deleteData, patchData, putData } from "./base";


const getClients = async () => {  
  return await getData("client");
};

const getAvailableScopes = async () => {
  return await getData("scopes");
};

const createClient = async (data) => {
  const form = new FormData();
  form.append("scope", data.scopes.join(" "));
  Object.keys(data).forEach(key => {
    if (key !== 'scopes') {
      form.append(key,data[key]);
    }
  })
  const response = await postData("client",form);
  response.message = response.status ? "[OK] Cliente creado correctamente" : response.message;
  return response;
}

const updateClient = async (id,data) => {
  const form = new FormData();
  form.append("scope", data.scopes.join(" "));
  Object.keys(data).forEach(key => {
    if (key !== 'scopes') {
      form.append(key,data[key]);
    }
  })
  const response = await putData(`client?client_id=${id}`,form);
  response.message = response.status ? "[OK] Cliente actualizado correctamente" : response.message;
  return response;
}

const deleteClient = async (id) => {
  return await deleteData(`client?client_id=${id}`);
}

const updateClientSecret = async (id) => {
  return await patchData(`client?client_id=${id}`);
}

export { createClient, updateClient, deleteClient, getClients, getAvailableScopes, updateClientSecret };
