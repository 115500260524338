import { useEffect } from "react";
import { connect } from "react-redux";
import { alerts } from "../actions";
import Fade from "@mui/material/Fade";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";

const CreateAlert = ({
  display,
  message,
  displayAlertFade,
  displayAlertClean,
}) => {
  useEffect(() => {
    if (display) {
      setTimeout(() => {
        displayAlertFade();
        setTimeout(() => {
          displayAlertClean();
        }, 1000);
      }, 2000);
    }
  }, [display]);

  return display || message !== "" ? (
    <Fade in={display} timeout={500}>
      <Box
        sx={{
          mt: 2,
          zIndex: 30,
          position: "absolute",
          top: 0,
          left: "50%",
          zIndex: 5000,
          transform: "translate(-50%)",
        }}
      >
        {
          <Alert severity={"success"} variant="filled">
            <AlertTitle> {"Peticion Correcta:"}</AlertTitle>
            {message}
          </Alert>
        }
      </Box>
    </Fade>
  ) : (
    <div />
  );
};

const mapStateToProps = (state) => {
  return {
    display: state.displayAlert.display,
    message: state.displayAlert.message,
  };
};

const mapActionsToProps = {
  displayAlertFade: alerts.displayAlertFade,
  displayAlertClean: alerts.displayAlertClean,
};

export default connect(mapStateToProps, mapActionsToProps)(CreateAlert);
