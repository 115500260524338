import { ACTIVE_TOKEN, EXPIRED_TOKEN } from "./types";


const activeToken = () => {
    return {
        type: ACTIVE_TOKEN,
        payload: { token: true },
    };
};

const expiredToken = () => {
    return {
        type: EXPIRED_TOKEN,
        payload: { token: false },
    };
};

export const changeTokenStatus = (value) => (dispatch) => {
    value ? dispatch(activeToken()) : dispatch(expiredToken());
}


