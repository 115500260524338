import CircularProgress from "@mui/material/CircularProgress";

function InfoTableDataHandler({
  children,
  isLoading,
  error,
  errorMessage,
  noData,
  noDataMessage
}) {
  return (
    <>
      { 
        isLoading ? (
          <CircularProgress size={40} />
        ) : error ? (
          errorMessage
        ) : noData ? (
          noDataMessage
        ) : (
          children
        )
      }  
    </>
  )
}

export default InfoTableDataHandler