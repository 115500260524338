import axios from "axios";

import store from "../store";

import { logout } from "actions/user";
import { changeTokenStatus } from "actions/token";
import { LOCAL_STORAGE_USER_TOKEN } from "../constants";

const { dispatch } = store;

const axiosInstance = axios.create({
  baseURL: "api",
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(LOCAL_STORAGE_USER_TOKEN);

  if (!token) {
    dispatch(changeTokenStatus(true));
    logout()
  }

  config.headers = {
    Authorization: `Bearer ${token}`,
  };
  
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return {
      code: response.status,
      data: response.data,
      message: "OK",
      status: true,
    };
  },
  (error) => {
    let { data, status } = error.response
    let message = data?.error
    if (message) {
      if (status === 498 || (status === 401 && message === "login_required")) {
        dispatch(changeTokenStatus(true));
        logout()
      }
      return {
        code: status,
        data: null,
        message,
        status: false,
      };
    }
    return {
      code: status,
      data: null,
      message: `Error desconocido: ${status}`,
      status: false,
    };
  }
);

export default axiosInstance;
