import { Drawer, Typography } from "@mui/material";

import AdminMenu from "./menus/AdminMenu";
import TestingMenu from "./menus/TestingMenu";
import Dualvision from "./dualvision/DualVision";
import DeveloperMenu from "./menus/DeveloperMenu";

function Sidebar({ drawerWidth }) {
  const drawerTheme = {
    width: drawerWidth,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: drawerWidth,
      boxSizing: "border-box",
    },
  };

  return (
    <Drawer variant="permanent" sx={drawerTheme} anchor="left">
      <Typography
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 150,
          lineHeight: 150,
        }}
      >
        <Dualvision size={25} />
      </Typography>
      {process.env.NODE_ENV === "development" && <TestingMenu />}
      <AdminMenu />
      <DeveloperMenu />
    </Drawer>
  );
}

export default Sidebar;
