import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

import { profile, alerts } from "../../actions";
import { stringAvatar } from "../../utils/avatar";

import ProfileDialog from "../dialogs/ProfileDialog";
import PageHeader from "../headers/PageHeader";

function MyProfileView({ profile, getProfile, username }) {
  const [showProfileDialog, setShowProfileDialog] = useState(false);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const CreateProfileButton = () => (
    <Button
      variant="outlined"
      sx={{
        fontWeight: 600,
      }}
      onClick={() => setShowProfileDialog(!showProfileDialog)}
    >
      Crear Perfil
    </Button>
  );

  return (
    <div style={{ marginLeft: "325px", marginRight: "2%" }}>
      <ProfileDialog open={showProfileDialog} setOpen={setShowProfileDialog} />
      <PageHeader
        title="Mi Perfil"
        children={(!profile || Object.keys(profile).length === 0) && <CreateProfileButton />}
      />
      {(!profile || Object.keys(profile).length === 0) ? (
        <div>
          No tienes un perfil asignado, puedes crear el tuyo pulsando el botón
          Crear Perfil
        </div>
      ) : (
        <div>
          <h3>Informacion basica</h3>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item md={3}>
              Foto
            </Grid>
            <Grid item md={9}>
              {profile.photo ? (
                <Avatar
                  alt={profile.name}
                  src={`data:image/png;base64,${profile.photo}`}
                  sx={{ width: 100, height: 100 }}
                />
              ) : (
                <Avatar
                  {...stringAvatar(profile.name)}
                  sx={{ width: 100, height: 100 }}
                />
              )}
            </Grid>

            <Grid item md={3}>
              Nombre
            </Grid>
            <Grid item md={9}>
              {profile.name}
            </Grid>

            <Grid item md={3}>
              Apellido
            </Grid>
            <Grid item md={9}>
              {profile.lastname}
            </Grid>

            <Grid item md={3}>
              Telefono
            </Grid>
            <Grid item md={9}>
              {profile.phone}
            </Grid>
          </Grid>
        </div>
      )}

      <div>
        <h3>Informacion de la cuenta</h3>
        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item md={3}>
            Nombre de usuario
          </Grid>
          <Grid item md={9}>
            {username}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.data,
    message: state.profile.message,
    errors: state.profile.errors,
    isLoading: state.profile.isLoading,
    username: state.user.username,
  };
};

const mapActionToProps = {
  getProfile: profile.getProfile,
  displayAlert: alerts.displayAlert,
};

export default connect(mapStateToProps, mapActionToProps)(MyProfileView);
