import {
  CLIENT_GET_LOADING,
  CLIENT_GET_SUCCESS,
  CLIENT_GET_FAILED,
  CLIENT_CREATE_LOADING,
  CLIENT_CREATE_SUCCESS,
  CLIENT_CREATE_FAILED,
  CLIENT_UPDATE_LOADING,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAILED,
  CLIENT_DELETE_LOADING,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAILED,
  CLIENT_RESET_MESSAGES,
  CLIENT_REFRESH_SECRET_LOADING,
  CLIENT_REFRESH_SECRET_SUCCESS,
  CLIENT_REFRESH_SECRET_FAILED,
} from "./types";
import { displayAlert } from "./display_alert";

import * as clientAPI from "../api/client";

const clientGetLoading = () => {
  return {
    type: CLIENT_GET_LOADING,
  };
};

const clientCreateLoading = () => {
  return {
    type: CLIENT_CREATE_LOADING,
  };
}

const clientUpdateLoading = () => {
  return {
    type: CLIENT_UPDATE_LOADING,
  };
}

const clientDeleteLoading = () => {
  return {
    type: CLIENT_DELETE_LOADING,
  }
}

const clientGetSuccess = (clients, message) => {
  return {
    type: CLIENT_GET_SUCCESS,
    payload: { message, clients },
  };
};

const clientCreateSuccess = (client, message) => {
  return {
    type: CLIENT_CREATE_SUCCESS,
    payload: { message, client },
  };
};

const clientUpdateSuccess = (client, message) => {
  return {
    type: CLIENT_UPDATE_SUCCESS,
    payload: { message, client },
  };
}

const clientDeleteSuccess = (id, message) => {
  return {
    type: CLIENT_DELETE_SUCCESS,
    payload: { id, message },
  }
}

const clientGetFailed = (message) => {
  return {
    type: CLIENT_GET_FAILED,
    payload: message,
  };
};

const clientCreateFailed = (message) => {
  return {
    type: CLIENT_CREATE_FAILED,
    payload: message,
  };
};

const clientUpdateFailed = (message) => { 
  return {
    type: CLIENT_UPDATE_FAILED,
    payload: message,
  };
}

const clientDeleteFailed = (message) => {
  return {
    type: CLIENT_DELETE_FAILED,
    payload: message,
  }
}

const clientResetMessages = () => {
  return {
    type: CLIENT_RESET_MESSAGES,
  };
};

const clientRefreshSecretLoading = () => {
  return {
    type: CLIENT_REFRESH_SECRET_LOADING
  };
};

const clientRefreshSecretSuccess = (client, message) => {
  return {
    type: CLIENT_REFRESH_SECRET_SUCCESS,
    payload: { client, message },
  };
};

const clientRefreshSecretFailed = (message) => {
  return {
    type: CLIENT_REFRESH_SECRET_FAILED,
    payload: message,
  };
};

export const getClients = () => {
  return async (dispatch) => {
    dispatch(clientGetLoading());

    const { status, data, message } = await clientAPI.getClients();

    if (status) {
      dispatch(clientGetSuccess(data.clients, message));
    } else {
      dispatch(clientGetFailed(message));
    }
  };
};

export const createClient = (newApplication) => {
  return async (dispatch) => {
    dispatch(clientCreateLoading());

    const { status, data, message } = await clientAPI.createClient(newApplication);

    if (status) {
      dispatch(clientCreateSuccess(data.client, message));
      dispatch(displayAlert(`Aplicación ${newApplication.client_name} creado con éxito`));
    } else {
      dispatch(clientCreateFailed(message));
    }

    setTimeout(() => {
      dispatch(clientResetMessages());
    }, 2000);
  };
};

export const updateClient = (id,newApplication) => {
  return async (dispatch) => {
    dispatch(clientUpdateLoading());

    const { status, data, message } = await clientAPI.updateClient(id,newApplication);

    if (status) {
      dispatch(clientUpdateSuccess(data.client, message));
      dispatch(displayAlert(`Aplicación ${newApplication.client_name} actualizado con éxito`));
    } else {
      dispatch(clientUpdateFailed(message));
    }

    setTimeout(() => {
      dispatch(clientResetMessages());
    }, 2000);
  };
};

export const deleteClient = (id) => {
  return async (dispatch) => {
    dispatch(clientDeleteLoading());
    const { status, data, message } = await clientAPI.deleteClient(id);

    if (status) {
      dispatch(clientDeleteSuccess(id, message));
      dispatch(displayAlert(`Aplicación eliminada con éxito`));
    } else {
      dispatch(clientDeleteFailed(message));
    }

    setTimeout(() => {
      dispatch(clientResetMessages());
    }, 2000);
  };
};

export const refreshClientSecret = (id) => {
  return async (dispatch) => {
    dispatch(clientRefreshSecretLoading());
    const { status, data, message } = await clientAPI.updateClientSecret(id);

    if (status) {
      dispatch(clientRefreshSecretSuccess(data.client, message));
      dispatch(displayAlert(`Client Secret actualizado con éxito`));
    } else {
      dispatch(clientRefreshSecretFailed(message));
    }

    setTimeout(() => {
      dispatch(clientResetMessages());
    }, 2000);
  };
};
