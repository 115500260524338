import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Home, Login } from "./pages";

import {
  AdminUserView,
  AdminRoleView,
  AdminPermissionView,
  DevAppView,
  AdminOrganizationView,
  ResourceServersView,
} from "./components/ViewPages";
import Authorization from "./pages/Authorization";
import MyProfileView from "./components/ViewPages/MyProfile";
import TestingPage from "pages/Testing";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />}/>
        <Route path="/" element={ <Home /> }>
          <Route path="/users" element={
            <AdminUserView />
          } />
          <Route path="/organizations" element={
              <AdminOrganizationView />
          } />
          <Route path="/permissions" element={
            <AdminPermissionView />
          } />
          <Route path="/roles" element={
            <AdminRoleView />
          } />
          <Route path="/rservers" element={
            <ResourceServersView />
          } />
          <Route path="/applications" element={
            <DevAppView />
          } />
          <Route path="/profile" element={
            <MyProfileView />
          } />
          {process.env.NODE_ENV === "development" && (
          <Route path="/testing" element={
            <TestingPage />
          } />
        )}
        </Route>
        <Route path="/oauth" element={
          <Authorization />
        } />
        <Route path="*" element={<div>404 - Not Found</div>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
