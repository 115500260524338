import { getData, postData } from "./base";

export const getAppGrantRequest = async (data) => {
  let params = {
    client_id: data.client_id,
    response_type: data.response_type,
    scope: data.scope,
    code_challenge: data.code_challenge,
    code_challenge_method: data.code_challenge_method,
    redirectUri: data.redirectUri,
  }

  if (data.state) {
    params.state = data.state;
  }

  return await getData("/oauth/authorize", params);
};

export const sendAppGrant = async (data) => {
  return await postData(data.url, { authorize: data.consent }, data.params);
};
