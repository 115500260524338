export const nameRules = {
  required: {
    value: true,
    message: "El nombre del permiso es obligatorio."
  },
  minLength: {
    value: 3,
    message: "El nombre del permiso debe tener al menos 3 carácteres."
  },
  maxLength: {
    value: 50,
    message: "El nombre del permiso debe tener como máximo 50 carácteres."
  },
  pattern: {
    value: /^([a-zA-Z0-9-_]+):([a-zA-Z0-9-_]+):([a-zA-Z0-9-_]+):([a-zA-Z0-9-_]+)$/,
    message: "El permiso debe tener el formato rserver:org:resource:level"
  }
}

export const descriptionRules = {
  required: {
    value: true,
    message: "La descripción del permiso es obligatoria."
  },
  minLength: {
    value: 3,
    message: "La descripción del permiso debe tener al menos 3 carácteres."
  },
  maxLength: {
    value: 255,
    message: "La descripción del permiso debe tener como máximo 255 carácteres."
  }
}

export const organizationIdRules = {
  required: {
    value: true,
    message: "La organización del permiso es obligatoria."
  }
}