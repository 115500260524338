import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  CssBaseline,
  Container,
  Box,
  Divider,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  CircularProgress,
} from "@mui/material";
import AddModeratorIcon from "@mui/icons-material/AddModerator";

import {
  TEXT_COLOR_FORM_V1,
  SECONDARY_BACKGROUND_COLOR,
  PRIMARY_BACKGROUND_COLOR,
  TITLE_DIALOG_TEXT_COLOR,
} from "../colorTheme";
import { DualVision } from "../components";
import { getAppGrantRequest, sendAppGrant } from "../api/oauth";

const theme = createTheme({
  palette: {
    background: {
      default: PRIMARY_BACKGROUND_COLOR,
    },
    text: {
      primary: TEXT_COLOR_FORM_V1,
    },
  },
});

const boxTheme = {
  backgroundColor: SECONDARY_BACKGROUND_COLOR,
  color: TEXT_COLOR_FORM_V1,
  mt: 1,
  p: 5,
  marginTop: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 2,
};

const appLogoTheme = {
  width: "150px",
  height: "150px",
};

const getCodeAndState = (url) => {
  const params = url.split("?")[1].split("&");
  const [code, state] = params.map((param) => {
    const [key, value] = param.split("=");
    if (key === "code" || key === "state") return value;
  });

  return { code, state };
};

export default function Authorization() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [appInfo, setAppInfo] = useState(null);
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  useEffect(async () => {
    const response_type = searchParams.get("response_type");
    const client_id = searchParams.get("client_id");
    const scope = searchParams.get("scope");
    const code_challenge = searchParams.get("code_challenge");
    const code_challenge_method = searchParams.get("code_challenge_method");
    const redirectUri = searchParams.get("redirect_uri");
    const state = searchParams.get("state");
    if (
      response_type &&
      client_id &&
      scope &&
      code_challenge &&
      code_challenge_method &&
      redirectUri
    ) {
      const response = await getAppGrantRequest({
        response_type,
        client_id,
        scope,
        code_challenge,
        code_challenge_method,
        state,
        redirectUri: encodeURIComponent(redirectUri),
      });
      if (response.status) {
        setAppInfo(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        if (response.message === "login_required") {
          setError("login");
        } else setError("oauth");
      }
    } else {
      setLoading(false);
      setError("oauth");
    }
  }, [searchParams]);

  useEffect(() => {
    if (error && error === "oauth") {
      navigate("/login", {
        replace: true,
        state: { error: "oauth" },
      });
    } else if (error && error === "login") {
      navigate("/login", {
        replace: true,
        state: {
          error: "token_expired",
          next: encodeURIComponent(
            window.location.pathname + window.location.search
          ),
        },
      });
    }
  }, [error, navigate]);

  const userGrantApplication = async (e, confirm) => {
    e.preventDefault();
    if (confirm === false) {
      sendAppGrant(false);
      navigate("/");
    } else {
      const response_type = searchParams.get("response_type");
      const client_id = searchParams.get("client_id");
      const scope = searchParams.get("scope");
      const code_challenge = searchParams.get("code_challenge");
      const code_challenge_method = searchParams.get("code_challenge_method");
      const redirectUri = searchParams.get("redirect_uri");
      const state = searchParams.get("state");

      let url = "oauth/authorize";

      let params = {
        client_id: client_id,
        response_type: response_type,
        scope: scope,
        code_challenge: code_challenge,
        code_challenge_method: code_challenge_method,
        redirectUri: encodeURIComponent(redirectUri),
      };

      if (state) {
        params.state = state;
      }

      const data = {
        url: url,
        params: params,
        consent: true,
      };

      const response = await sendAppGrant(data);
      if (response.status === true) {
        const result = getCodeAndState(response.data.redirect);
        window.location.href = `${decodeURIComponent(redirectUri)}?code=${
          result.code
        }${state ? `&state=${result.state}` : ""}`;
      } else {
        setError("login");
      }
    }
  };

  const permissions = {
    account:
      "Acceso a la información de los usuarios, roles y permisos de tu organización",
    organizations:
      "Acceso a la información de los proyectos, dispositivos y cámaras de tu organización",
    data:
      "Acceso a la información de los datos generados por nuestros sistemas de visión, tales como puntos de conteo, zonas, CCTV, entre otras de tu organización"
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={boxTheme}>
          <Box sx={{ mt: 2, mb: 2 }}>
            <DualVision />
          </Box>
          <Divider variant="middle" style={{ width: "100%" }} />
          {loading ? (
            <CircularProgress disableShrink sx={{ m: 20 }} />
          ) : (
            <div>
              <Grid container direction="column" spacing={2}>
                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  xs={12}
                >
                  <img
                    src={`data:image/png;base64,${
                      appInfo && appInfo.grant.client_logo
                    }`}
                    style={appLogoTheme}
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    La aplicación{" "}
                    <span style={{ color: TITLE_DIALOG_TEXT_COLOR }}>
                      {appInfo && appInfo.grant.client_name}
                    </span>{" "}
                    pide tu autorización para acceder a la siguiente
                    información.
                  </Typography>
                  <List dense={true}>
                    {appInfo && appInfo.grant
                      ? appInfo.grant.scope.split(" ").map((scope) => (
                          <ListItem key={scope}>
                            <ListItemAvatar>
                              <Avatar>
                                <AddModeratorIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={permissions[scope]} />
                          </ListItem>
                        ))
                      : null}
                  </List>
                </Grid>
              </Grid>
              <Box component="form" noValidate sx={{ mt: 2 }}>
                <Button
                  type="submit"
                  disabled={false}
                  sx={{ mr: 2 }}
                  onClick={(e) => userGrantApplication(e, false)}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  disabled={false}
                  color="secondary"
                  onClick={(e) => userGrantApplication(e, true)}
                >
                  Autorizar Aplicación
                </Button>
              </Box>
            </div>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
