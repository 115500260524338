import { useEffect, useState } from "react";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { GridActionsCellItem } from "@mui/x-data-grid";

import InfoTable from "../tables/InfoTable";
import { OrganizationDialog, ConfirmDialog } from "../dialogs";

import { hasPermission } from "utils/permissions";
import { organization } from "actions";
import { withPermissions } from "hocs";
import { ADMIN_ORGANIZATIONS_READ, ADMIN_ORGANIZATIONS_WRITE } from "constants";
import PageHeader from "../headers/PageHeader";

function AdminOrganizationView({
  organizations,
  message,
  errors,
  isLoading,
  getOrganizations,
  deleteOrganization,
}) {
  const [openDelete, setOpenDelete] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState(null);

  const [showOrganizationDialog, setShowOrganizationDialog] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setCurrentOrganization(null);
  };

  const handleDeleteSubmit = () => {
    deleteOrganization(currentOrganization.id);
    handleDeleteClose();
  };

  const CreateOrganizations = withPermissions(
    () => (
      <Button
        variant="outlined"
        onClick={() => {
          setFormValues(null);
          setShowOrganizationDialog(true);
        }}
      >
        Crear Organizacion
      </Button>
    ),
    [ADMIN_ORGANIZATIONS_WRITE],
    false
  );

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Organización", width: 200, flex: 1 },
    { field: "description", headerName: "Descripción", width: 400, flex: 1 },
    {
      field: "created_at",
      headerName: "Fecha de Creación",
      width: 200,
      flex: 1,
    },
    {
      field: "updated_at",
      headerName: "Fecha de Actualización",
      width: 200,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      hide: !hasPermission(ADMIN_ORGANIZATIONS_WRITE),
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon color="primary" />}
            onClick={() => {
              setFormValues({
                id: params.row.id,
                data: {
                  name: params.row.name,
                  description: params.row.description,
                },
              });
              setShowOrganizationDialog(true);
            }}
            label="Editar Organización"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="error"/>}
            onClick={() => {
              setCurrentOrganization(params.row);
              setOpenDelete(true);
            }}
            label="Eliminar Organización"
          />,
        ];
      },
    },
  ];
  useEffect(() => {
    if (!organizations || organizations.length === 0) {
      getOrganizations();
    }
  }, [getOrganizations]);

  return (
    <div style={{ marginLeft: "325px", marginRight: "2%" }}>
      <OrganizationDialog
        open={showOrganizationDialog}
        setOpen={setShowOrganizationDialog}
        formValues={formValues}
        setFormValues={setFormValues}
      />
      <ConfirmDialog
        open={openDelete}
        handleClose={handleDeleteClose}
        handleSubmit={handleDeleteSubmit}
      >
        ¿Está seguro que desea eliminar la organización {currentOrganization && currentOrganization.name}?,
        esta acción es irreversible.
      </ConfirmDialog>
      <PageHeader title="Organizaciones" children={<CreateOrganizations />} />
      <h3>Lista de Organizaciones registradas en Amadeus</h3>
      {isLoading ? (
        <CircularProgress size={40} />
      ) : errors && organizations === [] ? (
        message
      ) : organizations.length !== 0 ? (
        <Grid container>
          <InfoTable
            rows={organizations.map((org) => {
              return {
                id: org.id,
                name: org.name,
                description: org.description,
                created_at: new Date(org.created_at),
                updated_at: new Date(org.updated_at),
              };
            })}
            columns={columns}
          />
        </Grid>
      ) : (
        "No existen organizaciones"
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    organizations: state.organization.data,
    message: state.organization.message,
    errors: state.organization.errors,
    isLoading: state.organization.isLoading,
  };
};

const mapActionToProps = {
  getOrganizations: organization.getOrganizations,
  deleteOrganization: organization.deleteOrganization,
};

export default withPermissions(
  connect(mapStateToProps, mapActionToProps)(AdminOrganizationView),
  [ADMIN_ORGANIZATIONS_READ, ADMIN_ORGANIZATIONS_WRITE]
);
