import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

import no_image from "../../images/no_image.png";

import {
  composeValidators,
  mustHaveMaxSize,
  mustHaveMinSize,
} from "../../utils/validators";

const validate = {
  name: (name) =>
    composeValidators(mustHaveMinSize(name, 2), mustHaveMaxSize(name, 50)),
  lastname: (lastname) =>
    composeValidators(
      mustHaveMinSize(lastname, 2),
      mustHaveMaxSize(lastname, 50)
    ),
  phone: (_) => null,
  photo: (photo) =>
    photo !== undefined ? null : "Debes seleccionar una foto de perfil",
};

const initialValues = {
  name: "",
  lastname: "",
  phone: "",
  photo: undefined,
};

const ProfileForm = (props) => {
  const [selectedPhotoImage, setSelectedPhotoImage] = useState(undefined);
  const [preview, setPreview] = useState("");

  const handleProfilePicture = (e) => {
    if (!e.target.files || e.target.files.length == 0) {
      setSelectedPhotoImage(undefined);
      return;
    }

    setSelectedPhotoImage(e.target.files[0]);
  };

  const Input = styled("input")({
    display: "none",
  });

  const PREVIEW_IMAGE_SIZE = 200;

  const profilePicturePreviewClass = {
    width: `${PREVIEW_IMAGE_SIZE}px`,
    height: `${PREVIEW_IMAGE_SIZE}px`,
    border: "1px solid #ccc",
    margin: "auto",
    borderRadius: "50%",
  };
  useEffect(() => {
    if (!selectedPhotoImage) {
      setPreview(undefined);
      return;
    }

    const objectURL = URL.createObjectURL(selectedPhotoImage);
    setPreview(objectURL);

    return () => URL.revokeObjectURL(objectURL);
  }, [selectedPhotoImage]);

  const inputSX = { mt: 1, mb: 1 };
  return (
    <Box component="form" noValidate autoComplete="off">
      <Grid
        container
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={(evt) => {
                handleProfilePicture(evt);
                props.handleChange(undefined, {
                  name: "photo",
                  value:
                    !evt.target.files || evt.target.files.length == 0
                      ? undefined
                      : evt.target.files[0],
                });
              }}
            />
            <Button variant="text" component="span">
              <img
                src={selectedPhotoImage ? preview : no_image}
                style={profilePicturePreviewClass}
              />
            </Button>
          </label>
        </Grid>
        <Grid item>
          <Typography color="error">
            {props.touched.photo && props.errors.photo}
          </Typography>
        </Grid>
      </Grid>
      <TextField
        error={props.errors.name !== undefined ? true : false}
        value={props.values.name}
        onKeyPress={(e) => props.onEnterKeyPress(e, props.senderDataFn)}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        name="name"
        required
        label="Nombre"
        variant="standard"
        fullWidth
        color="primary"
        sx={inputSX}
        helperText={props.touched.name && props.errors.name}
      />
      <TextField
        error={props.errors.lastname !== undefined ? true : false}
        value={props.values.lastname}
        onKeyPress={(e) => props.onEnterKeyPress(e, props.senderDataFn)}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        name="lastname"
        required
        label="Apellido"
        variant="standard"
        fullWidth
        color="primary"
        sx={inputSX}
        helperText={props.touched.lastname && props.errors.lastname}
      />
      <TextField
        error={props.errors.phone !== undefined ? true : false}
        value={props.values.phone}
        onKeyPress={(e) => props.onEnterKeyPress(e, props.senderDataFn)}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        name="phone"
        label="Teléfono/Celular"
        variant="standard"
        color="primary"
        fullWidth
        sx={inputSX}
        helperText={props.touched.phone && props.errors.phone}
      />
      <Box sx={{ mt: 2, textAlign: "end" }}>
        <Button
          onClick={(e) => props.handleSubmit(e, props.senderDataFn)}
          disabled={props.hasToDisableButton}
        >
          Crear Perfil
        </Button>
      </Box>
    </Box>
  );
};

export { ProfileForm, validate, initialValues };
