import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DnsIcon from "@mui/icons-material/Dns";

import { withPermissions } from "../../hocs";
import { ADMIN_RESOURCE_SERVERS_WRITE } from "../../constants";

const ResourceServerMenu = () => {
  return (
    <ListItem button component={Link} to="/rservers">
      <ListItemIcon>
        <DnsIcon />
      </ListItemIcon>
      <ListItemText primary={"Servidores de Recursos"} />
    </ListItem>
  );
};

export default withPermissions(
  ResourceServerMenu,
  [ADMIN_RESOURCE_SERVERS_WRITE],
  false
);
