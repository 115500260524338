import { useForm, Controller } from 'react-hook-form';

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { nameRules, descriptionRules, permissionRules } from "./rules";

const RoleForm = ({
  values,
  submit,
  permissions,
  handleClose,
  disableButton,
  submitButtonText
}) => {
  const initialValues = {
    name: "",
    description: "",
    permissions: [],
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const { control, handleSubmit } = useForm({
    defaultValues: values ?? initialValues,
  });

  const onSubmit = (data) => {
    submit(data);
  };

  const inputSX = { mt: 1, mb: 1 };

  return ( <>
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Controller
        name="name"
        rules={nameRules}
        control={control}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { error },
        }) => {
          return (
            <TextField
              error={error !== undefined}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              label="Nombre del Rol"
              variant="standard"
              color="primary"
              helperText={error?.message}
              sx={inputSX}
            />
          );
        }}
      />
      <Controller
        name="description"
        rules={descriptionRules}
        control={control}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { error },
        }) => {
          return (
            <TextField
              error={error !== undefined}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              label="Descripción del Rol"
              variant="standard"
              color="primary"
              multiline
              helperText={error?.message}
              sx={inputSX}
            />
          );
        }}
      />
      <Controller
        name="permissions"
        rules={permissionRules}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error }
        }) =>{
          return (
            <Autocomplete
              multiple
              value={value}
              options={permissions}
              disableCloseOnSelect
              onChange={(_, data) => {
                onChange(data);
              }}
              groupBy={(option) => option.organization.name}
              getOptionLabel={(option) => option.name }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option, { selected }) => {
                return ( <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                  </li> )
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  error={error !== undefined}
                  label="Permisos"
                  variant="standard"
                  color="primary"
                  helperText={error?.message}
                  sx={inputSX}
                />
              )}
            />
          );
        }}
      />
      <Box sx={{ mt: 2, textAlign: "end" }}>
          <Button onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" disabled={disableButton}>
            {submitButtonText ?? "Enviar"}
          </Button>
        </Box>
    </Box> 
  </> )  
}

export default RoleForm