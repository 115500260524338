import { useForm, Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const OrganizationForm = ({ values, submit, handleClose, disableButton, submitButtonText }) => {
  const initialValues = {
    name: "",
    description: "",
  };
  const { control, handleSubmit } = useForm({
    defaultValues: values ?? initialValues,
  });

  const onSubmit = (data) => {
    submit(data);
  };

  const nameRules = {
    required: { value: true, message: "El nombre es obligatorio." },
    minLength: {
      value: 3,
      message: "El nombre debe tener como mínimo 3 carácteres.",
    },
    maxLength: {
      value: 120,
      message: "El nombre no puede sobrepasar los 120 carácteres.",
    },
  };

  const descriptionRules = {
    required: { value: true, message: "La descripción es obligatoria" },
    minLength: {
      value: 3,
      message: "La descripción debe tener como mínimo 3 carácteres.",
    },
    maxLength: {
      value: 255,
      message: "La descripción no puede sobrepasar los 255 carácteres.",
    },
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Controller
          name="name"
          rules={nameRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                label="Nombre de la Organización"
                variant="standard"
                color="primary"
                helperText={error && error.message}
                sx={{mb: 2}}
              />
            );
          }}
        />
        <Controller
          name="description"
          rules={descriptionRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                label="Descripción de la Organización"
                variant="standard"
                color="primary"
                multiline
                rows={3}
                helperText={error && error.message}
              />
            );
          }}
        />
        <Box sx={{ mt: 2, textAlign: "end" }}>
          <Button onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" disabled={disableButton}>
            {submitButtonText ? submitButtonText : "Enviar"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default OrganizationForm;
