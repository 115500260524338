import {
  ORGANIZATION_RESET_MESSAGES,
  ORGANIZATION_GET_LOADING,
  ORGANIZATION_GET_SUCCESS,
  ORGANIZATION_GET_FAILED,
  ORGANIZATION_CREATE_LOADING,
  ORGANIZATION_CREATE_SUCCESS,
  ORGANIZATION_CREATE_FAILED,
  ORGANIZATION_EDIT_LOADING,
  ORGANIZATION_EDIT_SUCCESS,
  ORGANIZATION_EDIT_FAILED,
  ORGANIZATION_DELETE_LOADING,
  ORGANIZATION_DELETE_SUCCESS,
  ORGANIZATION_DELETE_FAILED,
} from "../actions/types";

const initialState = {
  isLoading: false,
  errors: false,
  message: null,
  data: [],
};

export default function organization(state = initialState, action) {
  switch (action.type) {
    case ORGANIZATION_RESET_MESSAGES:
      return {
        ...state,
        message: null,
        isLoading: false,
        errors: false,
      };
    case ORGANIZATION_GET_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Obteniendo organizaciones",
      };

    case ORGANIZATION_CREATE_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Creando organización",
      };
    case ORGANIZATION_EDIT_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Editando organización",
      };
    case ORGANIZATION_DELETE_LOADING:
      return {
        ...state,
        isLoading: true,
        message: "Eliminando organización",
      };
    case ORGANIZATION_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: action.payload.organizations,
      };
    case ORGANIZATION_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: [...state.data, action.payload.organization],
      };
    case ORGANIZATION_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: state.data.map((organization) =>
          organization.id === action.payload.organization.id
            ? action.payload.organization
            : organization
        ),
      };
    case ORGANIZATION_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: false,
        message: action.payload.message,
        data: state.data.filter(
          (organization) => organization.id !== action.payload.id
        ),
      };
    case ORGANIZATION_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload.message,
        data: [],
      };
    case ORGANIZATION_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      };
    case ORGANIZATION_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      };
    case ORGANIZATION_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: true,
        message: action.payload,
      };
    default:
      return { ...state };
  }
}
