import { connect } from "react-redux";
import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";

import InfoTable from "../tables/InfoTable";
import InfoTableDataHandler from "../handlers/InfotableDataHandler";

import { PermissionDialog, ConfirmDialog } from "../dialogs";

import { hasPermission } from "../../utils/permissions";
import { permission } from "../../actions";
import { withPermissions } from "../../hocs";
import {
  ADMIN_PERMISSIONS_READ,
  ADMIN_PERMISSIONS_WRITE,
} from "../../constants";
import PageHeader from "../headers/PageHeader";

function AdminPermissionView({
  permissions,
  message,
  errors,
  isLoading,
  getPermissions,
  deletePermission
}) {
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPermission, setCurrentPermission] = useState(null);
  
  const [showPermissionDialog, setShowPermissionDialog] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setCurrentPermission(null);
  }

  const handleDeleteSubmit = () => {
    deletePermission(currentPermission.id);
    handleDeleteClose();
  }

  const CreatePermissions = withPermissions(
    () => ( 
      <Button
        variant="outlined"
        sx={{
          fontWeight: 600,
        }}
        onClick={() => setShowPermissionDialog(true)}
      >
        Crear Permiso
      </Button>
    ),
    [ADMIN_PERMISSIONS_WRITE],
    false
  );
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Permiso", width: 200, flex: 1 },
    { field: "description", headerName: "Descripción", width: 300, flex: 1 },
    { field: "organization_id", headerName: "ID Organización", width: 70, flex: 1 },
    { field: "organization_name", headerName: "Nombre Organización", width: 200, flex: 1 },
    { field: "created_at", headerName: "Fecha de Creación", width: 200, flex: 1 },
    { field: "updated_at", headerName: "Fecha de Actualización", width: 200, flex: 1 },
    {
      field: "actions",
      type: "actions",
      hide: !hasPermission(ADMIN_PERMISSIONS_WRITE),
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary"/>}
          onClick={() => {
            setFormValues({
              id: params.row.id,
              data: {
                name: params.row.name,
                description: params.row.description,
                organization_id: params.row.organization_id,
              },
            });
            setShowPermissionDialog(true);
          }}
          label="Editar Permiso"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon color="error"/>}
          onClick={() => {
            setCurrentPermission(params.row);
            setOpenDelete(true);
          }}
          label="Eliminar Permiso"
        />,
      ],
    },
  ];

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  return (
    <div style={{ marginLeft: "325px", marginRight: "2%" }}>
      <PermissionDialog
        open={showPermissionDialog}
        setOpen={setShowPermissionDialog}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <ConfirmDialog
        open={openDelete}
        handleClose={handleDeleteClose}
        handleSubmit={handleDeleteSubmit}
      >
        ¿Está seguro que desea eliminar el permiso {currentPermission?.name}?,
        esta acción es irreversible.
      </ConfirmDialog>

      <PageHeader
        title="Permisos"
        children={<CreatePermissions />}
      />
      <h3>Lista de Permisos registrados en Amadeus</h3>
      <Grid container>
        <InfoTableDataHandler
          isLoading={isLoading}
          error={errors}
          errorMessage={message}
          noData={!permissions.length}
          noDataMessage="No hay permisos registrados"
        >
          <InfoTable
            rows={permissions.map((per) => {
              return {
                id: per.id,
                name: per.name,
                description: per.description,
                organization_id: per.organization.id,
                organization_name: per.organization.name,
                created_at: new Date(per.created_at),
                updated_at: new Date(per.updated_at),
              };
            })}
            columns={columns}
          />
        </InfoTableDataHandler>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permission.data,
    message: state.permission.message,
    errors: state.permission.errors,
    isLoading: state.permission.isLoading,
  };
};

const mapActionToProps = {
  getPermissions: permission.getPermissions,
  deletePermission: permission.deletePermission,
};

export default withPermissions(
  connect(mapStateToProps, mapActionToProps)(AdminPermissionView),
  [ADMIN_PERMISSIONS_READ, ADMIN_PERMISSIONS_WRITE]
);
