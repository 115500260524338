import * as permissionAPI from "../api/permission";

import {
  PERMISSION_GET_LOADING,
  PERMISSION_GET_SUCCESS,
  PERMISSION_GET_FAILED,
  PERMISSION_RESET_MESSAGES,
  PERMISSION_CREATE_FAILED,
  PERMISSION_CREATE_SUCCESS,
  PERMISSION_CREATE_LOADING,
  PERMISSION_EDIT_FAILED,
  PERMISSION_EDIT_SUCCESS,
  PERMISSION_EDIT_LOADING,
  PERMISSION_DELETE_FAILED,
  PERMISSION_DELETE_SUCCESS,
  PERMISSION_DELETE_LOADING,
} from "./types";
import { displayAlert } from "./display_alert";

const permissionGetLoading = () => {
  return {
    type: PERMISSION_GET_LOADING,
  };
};

const permissionCreateLoading = () => {
  return {
    type: PERMISSION_CREATE_LOADING,
  };
};

const permissionEditLoading = () => {
  return {
    type: PERMISSION_EDIT_LOADING,
  };
};

const permissionDeleteLoading = () => {
  return {
    type: PERMISSION_DELETE_LOADING,
  };
};

const permissionGetSuccess = (permissions, message) => {
  return {
    type: PERMISSION_GET_SUCCESS,
    payload: { message, permissions },
  };
};

const permissionCreateSuccess = (permission, message) => {
  return {
    type: PERMISSION_CREATE_SUCCESS,
    payload: { message, permission },
  };
};

const permissionEditSuccess = (permission, message) => {
  return {
    type: PERMISSION_EDIT_SUCCESS,
    payload: { message, permission },
  }
};

const permissionDeleteSuccess = (id, message) => {
  return {
    type: PERMISSION_DELETE_SUCCESS,
    payload: { id, message },
  }
};

export const permissionGetFailed = (message) => {
  return {
    type: PERMISSION_GET_FAILED,
    payload: message,
  };
};

export const permissionCreateFailed = (message) => {
  return {
    type: PERMISSION_CREATE_FAILED,
    payload: message,
  };
};

export const permissionEditFailed = (message) => {
  return {
    type: PERMISSION_EDIT_FAILED,
    payload: message,
  };
};

export const permissionDeleteFailed = (message) => {
  return {
    type: PERMISSION_DELETE_FAILED,
    payload: message,
  };
};

export const permissionResetMessages = () => {
  return {
    type: PERMISSION_RESET_MESSAGES,
  };
};

export const getPermissions = () => {
  return async (dispatch) => {
    dispatch(permissionGetLoading());
    
    const { status, data, message } = await permissionAPI.getPermissions();

    if (status) {
      dispatch(
        permissionGetSuccess(data.permissions, message)
      );
    } else {
      dispatch(permissionGetFailed(message));
    }
  };
};

export const createPermission = (permissionData) => {
  return async (dispatch) => {
    dispatch(permissionCreateLoading());

    const { status, data, message } = await permissionAPI.createPermission(permissionData);

    if (status) {
      dispatch(permissionCreateSuccess(data, message));
      dispatch(displayAlert(`Permiso ${permissionData.name} creado con éxito`));
    } else {
      dispatch(permissionCreateFailed(message));
    }

    setTimeout(() => {
      dispatch(permissionResetMessages());
    }, 2000);
  };
};

export const editPermission = (id, editData) => {
  return async (dispatch) => {
    dispatch(permissionEditLoading());
    
    const { status, data, message } = await permissionAPI.editPermission(id, editData);

    if (status) {
      dispatch(permissionEditSuccess(data, message));
      dispatch(displayAlert(`Permiso ${editData.name} editado con éxito`));
    } else {
      dispatch(permissionEditFailed(message));
    }

    setTimeout(() => {
      dispatch(permissionResetMessages());
    }, 2000);
  };
}

export const deletePermission = (id) => {
  return async (dispatch) => {
    dispatch(permissionDeleteLoading());
    const { status, message } = await permissionAPI.deletePermission(id);

    if (status) {
      dispatch(permissionDeleteSuccess(id, message));
      dispatch(displayAlert(`Permiso eliminado con éxito`));
    } else {
      dispatch(permissionDeleteFailed(message));
    }

    setTimeout(() => {
      dispatch(permissionResetMessages());
    }, 2000);
  };
}
