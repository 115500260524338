import { useEffect, useState } from "react";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";

import InfoTable from "../tables/InfoTable";
import InfoTableDataHandler from "../handlers/InfotableDataHandler";
import { UserDialog, ConfirmDialog } from "../dialogs";

import { hasPermission } from "../../utils/permissions";
import { user } from "../../actions";
import { withPermissions } from "../../hocs";
import { ADMIN_USERS_READ, ADMIN_USERS_WRITE } from "../../constants";
import PageHeader from "../headers/PageHeader";

function UserView({
  users,
  message,
  errors,
  isLoading,
  getUsers,
  deleteUser
}) {
  const [openDelete, setOpenDelete] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [showUserDialog, setShowUserDialog] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setCurrentUser(null);
  };

  const handleDeleteSubmit = () => {
    deleteUser(currentUser.id);
    handleDeleteClose();
  };


  const CreateUser = withPermissions(
    () => (
      <Button
        variant="outlined"
        sx={{
          fontWeight: 600,
        }}
        onClick={() => setShowUserDialog(true)}
      >
        Crear Usuario
      </Button>
    ),
    [ADMIN_USERS_WRITE],
    false
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "username", headerName: "Nombre de Usuario", width: 150, flex: 1 },
    { field: "email", headerName: "Correo Electrónico", width: 200, flex: 1 },
    { field: "roles", headerName: "Roles Asociados", width: 150, flex: 1 },
    { field: "created_at", headerName: "Fecha de Creación", width: 200, flex: 1 },
    { field: "updated_at", headerName: "Fecha de Actualización", width: 200, flex: 1 },
    {
      field: "actions",
      type: "actions",
      hide: !hasPermission(ADMIN_USERS_WRITE),
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary"/>}
          onClick={() => {
            setFormValues({
              id: params.row.id,
              data: {
                username: params.row.username,
                email: params.row.email,
                roles: users.find((user) => user.id === params.row.id).roles,
              },
            });
            setShowUserDialog(true);
          }}
          label="Editar Usuario"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon color="error"/>}
          onClick={() => {
            setCurrentUser(params.row);
            setOpenDelete(true);
          }}
          label="Eliminar Usuario"
        />,
      ],
    },
  ];

  return (
    <div style={{ marginLeft: "325px", marginRight: "2%" }}>
      <UserDialog
        open={showUserDialog}
        setOpen={setShowUserDialog}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <ConfirmDialog
        open={openDelete}
        handleClose={handleDeleteClose}
        handleSubmit={handleDeleteSubmit}
      >
        ¿Está seguro que desea eliminar el usuario {currentUser?.username}?,
        esta acción es irreversible.
      </ConfirmDialog>

      <PageHeader
        title="Usuarios"
        children={<CreateUser />}
      />

      <h3>Lista de Usuarios registrados en Amadeus</h3>
      <Grid container>
        <InfoTableDataHandler
          isLoading={isLoading}
          error={errors}
          errorMessage={message}
          noData={!users.length}
          noDataMessage="No hay usuarios registrados"
        >
          <InfoTable
            rows={users.map((user) => {
              return {
                id: user.id,
                username: user.username,
                email: user.email,
                roles: user.roles.length,
                created_at: new Date(user.created_at),
                updated_at: new Date(user.updated_at),
              };
            })}
            columns={columns}
          />
        </InfoTableDataHandler>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.get_users.data,
    message: state.get_users.message,
    errors: state.get_users.errors,
    isLoading: state.get_users.isLoading,
  };
};

const mapActionToProps = {
  getUsers: user.getUsers,
  deleteUser: user.deleteUser
};

export default withPermissions(
  connect(mapStateToProps, mapActionToProps)(UserView),
  [ADMIN_USERS_READ, ADMIN_USERS_WRITE]
)