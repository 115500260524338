import {
    DISPLAY_ALERT,
    DISPLAY_ALERT_FADE,
    DISPLAY_ALERT_CLEAN
} from './types';


const dispAlert = (message) => ({
    type: DISPLAY_ALERT,
    payload: message
})

const notDispAlert = () => ({
    type: DISPLAY_ALERT_FADE,
})

const cleanDispAlert = () => ({
    type: DISPLAY_ALERT_CLEAN
})


export const displayAlert = (message) => (dispatch) => {
    dispatch(dispAlert(message));
}

export const displayAlertFade = () => (dispatch) => {
    dispatch(notDispAlert())
}

export const displayAlertClean = () => (dispatch) => {
    dispatch(cleanDispAlert())
}
