import { useEffect } from "react";
import { connect } from "react-redux";

import { rserver } from "actions";
import ResourceServerForm from "components/forms/ResourceServerForm";

import FormDialog from "./FormDialog";

const ResourceServerDialog = ({
  open,
  close,
  setOpen,
  isLoading,
  message,
  createResourceServer,
  error,
  editResourceServer,
  formValues,
  setFormValues,
}) => {
  const handleClose = () => {
    setFormValues(null);
    setOpen(false);
  };

  const onSubmit = (data) => {
    return !formValues
      ? createResourceServer(data)
      : editResourceServer(formValues.id, data);
  };

  useEffect(() => {
    if (close) handleClose();
  }, [close]);

  return (
    <FormDialog
      open={open}
      title={
        formValues === null
          ? "Crear Servidor de Recursos"
          : `Editar Servidor de Recursos: #${formValues.id} ${formValues.data.server_name}`
      }
      description={
        <span>
          Estás apunto de {!formValues ? "crear" : "editar"} un servidor de
          recursos, que tendrá derecho a decodificar los tokens generados por
          las aplicaciones de Amadeus. <br />
          Por favor ingresa los datos solicitados a continuación.
        </span>
      }
      showMessage={error && !!message}
      errorMessage={message}
      form={
        <ResourceServerForm
          values={formValues ? formValues.data : undefined}
          submit={onSubmit}
          handleClose={handleClose}
          disableButton={isLoading || (message && message !== "OK")}
          submitButtonText={
            !formValues
              ? "Crear Servidor de Recursos"
              : "Editar Servidor de Recursos"
          }
        />
      }
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.rserver.isLoading,
    error: state.rserver.errors,
    message: state.rserver.message,
    close: state.displayAlert.display,
    open: ownProps.open,
    setOpen: ownProps.setOpen,
    formValues: ownProps.formValues,
    setFormValues: ownProps.setFormValues,
  };
};

const mapActionToProps = {
  createResourceServer: rserver.createResourceServer,
  editResourceServer: rserver.editResourceServer,
};

export default connect(mapStateToProps, mapActionToProps)(ResourceServerDialog);
