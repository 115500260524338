import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

import { withPermissions } from "../../hocs";
import { ADMIN_ROLES_READ, ADMIN_ROLES_WRITE } from "../../constants";

const RoleMenu = () => {
  return (
    <ListItem button component={Link} to="/roles">
              <ListItemIcon>
                <GroupAddIcon />
              </ListItemIcon>
              <ListItemText primary={"Roles"} />
            </ListItem>
  );
};

export default withPermissions(
  RoleMenu,
  [ADMIN_ROLES_READ, ADMIN_ROLES_WRITE],
  false
);
