export const usernameRules = {
    required: { value: true, message: "El nombre de usuario es obligatorio." },
    minLength: {
      value: 3,
      message: "El nombre de usuario debe tener como mínimo 3 carácteres.",
    },
    maxLength: {
      value: 120,
      message: "El nombre de usuario no puede sobrepasar los 50 carácteres.",
    },
  };

export const passwordRules = {
    required: { value: true, message: "La contraseña es obligatoria." },
    minLength: {
      value: 8,
      message: "La contraseña debe tener como mínimo 8 carácteres.",
    },
    pattern: {
        value: /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[@#$\.])[\w\d@#$\.]{8,}$/,
        message: "La contraseña debe tener al menos 8 caracteres, incluyendo una mayúscula, una minúscula, un número y un caracter especial"
    }
  };

export const emailRules = {
    required: {
      value: true,
      message: "El email es obligatorio.",
    },
    pattern: {
      value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "El email debe tener un formato válido.",
    }
  };
