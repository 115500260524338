import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const PageHeader = (props) => {
  const hasChildren = props.children;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={hasChildren ? 8 : 12}>
        <h1>{props.title}</h1>
        <hr />
      </Grid>
      {hasChildren && (
        <Grid item xs={4} alignItems="center">
          <Box display="flex" justifyContent="center">
            {props.children}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default PageHeader;
