import {
    PERMISSION_GET_LOADING,
    PERMISSION_GET_SUCCESS,
    PERMISSION_GET_FAILED,
    PERMISSION_RESET_MESSAGES,
    PERMISSION_CREATE_LOADING,
    PERMISSION_CREATE_SUCCESS,
    PERMISSION_CREATE_FAILED,
    PERMISSION_EDIT_LOADING,
    PERMISSION_EDIT_SUCCESS,
    PERMISSION_EDIT_FAILED,
    PERMISSION_DELETE_LOADING,
    PERMISSION_DELETE_SUCCESS,
    PERMISSION_DELETE_FAILED,
  } from "../actions/types";
  
  const initialState = {
    isLoading: false,
    errors: false,
    message: null,
    data: []
  };
  
  export default function permission(state = initialState, action) {
    switch (action.type) {
      case PERMISSION_RESET_MESSAGES:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: null,
        }
      case PERMISSION_CREATE_LOADING:
        return {
          ...state,
          isLoading: true,
          message: "Creando permiso..."
        }
      case PERMISSION_GET_LOADING:
        return {
          ...state,
          isLoading: true,
          message: "Obteniendo permisos",
        };
      case PERMISSION_EDIT_LOADING:
        return {
          ...state,
          isLoading: true,
          message: "Editando permiso..."
        }
      case PERMISSION_DELETE_LOADING:
        return {
          ...state,
          isLoading: true,
          message: "Eliminando permiso..."
        }
      case PERMISSION_GET_SUCCESS:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: action.payload.message,
          data: action.payload.permissions,
        };
      case PERMISSION_CREATE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: action.payload.message,
          data: [...state.data, action.payload.permission],
        }
      case PERMISSION_EDIT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: action.payload.message,
          data: state.data.map(permission => {
            if (permission.id === action.payload.permission.id) {
              return action.payload.permission;
            }
            return permission;
          }),
        }
      case PERMISSION_DELETE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: action.payload.message,
          data: state.data.filter(
            (permission) => permission.id !== action.payload.id
          )
        };
      case PERMISSION_GET_FAILED:
        return {
          ...state,
          isLoading: false,
          errors: true,
          message: action.payload,
          data: [],
        };
      case PERMISSION_CREATE_FAILED:
        return {
          ...state,
          isLoading: false,
          errors: true,
          message: action.payload
        }
      case PERMISSION_EDIT_FAILED:
        return {
          ...state,
          isLoading: false,
          errors: true,
          message: action.payload
        }
      case PERMISSION_DELETE_FAILED:
        return {
          ...state,
          isLoading: false,
          errors: true,
          message: action.payload
        }
      default:
        return { ...state };
    }
  }
  