import { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ReplayIcon from '@mui/icons-material/Replay';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Button,
  Autocomplete,
  Box,
  Input,
  Checkbox,
  MenuItem,
} from "@mui/material";

import {
  clientNameRules,
  clientDescriptionRules,
  clientUriRules,
  redirectUrisRules,
  grantTypesRules,
  responseTypesRules,
  tokenEndpointAuthMethodRules,
  scopesRules,
} from "./rules";

import { applicationImagePreviewClass } from "./commons";

/**
 * Transforma un objeto Date de Javascript, en un string
 * con el formato de hora Chilena dd/mm/yyyy hh:mm:ss
 * @param {Date} date
 * @returns {string}
 */
const formatDate = (date) => {
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const splitScopes = (scope) => {
  return scope.split(" ");
}

const AppEditForm = ({
  scopes,
  values,
  submit,
  handleClose,
  disableButton,
  submitButtonText,
  cancelButtonText,
  setRefresh,
}) => {
  const initialValues = {
    client_name: "",
    client_description: "",
    client_uri: "",
    redirect_uris: "",
    grant_types: "",
    response_types: "",
    scopes: [],
    token_endpoint_auth_method: "",
    image: "",
  }

  const imageBase64 = `data:image/png;base64,${values.logo}`;

  const setDefaultValues = (initialValues, values) => {
    if (values) {
      const defaultValues = Object.keys(initialValues).reduce((acc, val) => {
        if (val === 'scopes') {
          acc.scopes = splitScopes(values.scope);
        } else if (val === 'client_description') {
          acc.client_description = values.description;
        } else if (val === 'image') {
          acc.image = "";
        } else {
          acc[val] = values[val];
        }
        return acc;
      }, {})
      return defaultValues;
    }
    return initialValues;
  };

  const imageRef = useRef();
  const [preview, setPreview] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: setDefaultValues(initialValues, values)
  });

  const handlePicture = () => {
    const files = imageRef.current.firstChild.files;
    if (files && files[0]) {
      const image = files[0];
      const objectURL = URL.createObjectURL(image);
      setPreview(objectURL);
    } else {
      setPreview("");
    }
  };

  const handlePictureOnClose = () => {
    reset();
    handleClose();
  }


  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const inputSX = { mt: 1, mb: 1 };

  const onSubmit = (data) => {
    const input = imageRef.current.firstChild;
    const image = input.files[0];
    data.image = image;
    submit(data);
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autocomplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >

        <Grid container direction="column" spacing={2}>
          <Grid container item direction="row" xs={12} spacing={2}>
            <Controller
              name="image"
              control={control}
              render={({
                field: { onChange, onBlur, value, name },
              }) => (
                <Button
                  disabled={disableButton}
                  variant="text"
                  component="label"
                  sx={{margin: "auto"}}
                >
                  <Input
                    ref={imageRef}
                    accept="image/*"
                    type="file"
                    name={name}
                    value={value}
                    onChange={(event) => {
                      handlePicture();
                      onChange(event);
                    }}
                    onBlur={onBlur}
                    sx={{display: "none"}}
                  />
                  <img
                    src={disableButton
                         ? imageBase64
                         : preview || imageBase64}
                    alt="preview"
                    style={applicationImagePreviewClass}
                  />
                </Button>
              )}
            />
            <Grid item xs={12}>
              <Controller
                name="client_name"
                rules={clientNameRules}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      required
                      label="Nombre de la Aplicación"
                      type="text"
                      fullWidth
                      sx={{ marginTop: "5px" }}
                      disabled={disableButton}
                      helperText={error?.message}
                      error={error !== undefined}
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="client_description"
                rules={clientDescriptionRules}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      required
                      label="Descripción de la Aplicación"
                      type="text"
                      fullWidth
                      sx={{ marginTop: "5px" }}
                      disabled={disableButton}
                      helperText={error?.message}
                      error={error !== undefined}
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Client ID"
                type="text"
                fullWidth
                disabled={true}
                value={values.client_id}
              />
            </Grid>

            {values.client_secret !== null ? (
              <Grid container item direction="row" xs={12} spacing={2}>
                <Grid item xs={11}>
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel htmlFor="standard-adornment-password">
                      Client Secret
                    </InputLabel>
                    <OutlinedInput
                      id="standard-adornment-password"
                      label="Client Secret"
                      type={showPassword ? "text" : "password"}
                      value={showPassword ? values.client_secret : "********"}
                      disabled={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} sx={{ alignSelf: "center" }}>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => setRefresh(true)}
                  >
                    <ReplayIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Controller
                name="client_uri"
                rules={clientUriRules}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      required
                      label="URL de la Aplicacion"
                      type="text"
                      fullWidth
                      disabled={disableButton}
                      helperText={error?.message}
                      error={error !== undefined}
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="redirect_uris"
                rules={redirectUrisRules}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      required
                      label="URLs de Redirección"
                      type="text"
                      fullWidth
                      disabled={disableButton}
                      helperText={error?.message}
                      error={error !== undefined}
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="scopes"
                rules={scopesRules}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  return (
                    <Autocomplete
                      multiple
                      value={value}
                      options={scopes}
                      disabled={disableButton}
                      disableCloseOnSelect
                      onChange={(_, data) => {
                        onChange(data);
                      }}
                      getOptionLabel={(option) => option}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderOption={(props, option, { selected }) => {
                        return (<li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label="Scopes"
                          color="primary"
                          error={ error !== undefined }
                          helperText={error?.message}
                          sx={inputSX}
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="grant_types"
                rules={grantTypesRules}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      label="Grant Types"
                      select
                      fullWidth
                      disabled={disableButton}
                      error={error !== undefined}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                    >
                      <MenuItem value="authorization_code">Authorization Code</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="response_types"
                rules={responseTypesRules}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      label="Response Types"
                      select
                      fullWidth
                      disabled={disableButton}
                      name={name}
                      error={error !== undefined}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                    >
                      <MenuItem value="code">Code</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="token_endpoint_auth_method"
                rules={tokenEndpointAuthMethodRules}
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      label="Token Endpoint Auth Method"
                      select
                      fullWidth
                      disabled={disableButton}
                      name={name}
                      error={error !== undefined}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                    >
                      <MenuItem value="none">Ninguno (Aplicación Pública)</MenuItem>
                      <MenuItem value="client_secret_post">Client Secret Post</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Fecha de Creación"
                type="text"
                fullWidth
                disabled={true}
                value={formatDate(new Date(values.created_at))}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Fecha de Última Actualización"
                type="text"
                fullWidth
                disabled={true}
                value={formatDate(new Date(values.updated_at))}
              />
            </Grid>
            <Grid item xs={7}>
              <Button
                variant="outlined"
                sx={{ fontWeight: 600 }}
                onClick={disableButton ? handleClose : handlePictureOnClose}
              >
                {cancelButtonText}
              </Button>
            </Grid>
            <Grid item xs={5} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                type="submit"
                variant="outlined"
                sx={{ fontWeight: 600 }}
              >
                {submitButtonText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AppEditForm;