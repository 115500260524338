import {
    ROLE_GET_LOADING,
    ROLE_GET_SUCCESS,
    ROLE_GET_FAILED,
    ROLE_CREATE_LOADING,
    ROLE_CREATE_SUCCESS,
    ROLE_CREATE_FAILED,
    ROLE_EDIT_LOADING,
    ROLE_EDIT_SUCCESS,
    ROLE_EDIT_FAILED,
    ROLE_DELETE_LOADING,
    ROLE_DELETE_SUCCESS,
    ROLE_DELETE_FAILED,
    ROLE_RESET_MESSAGES,
  } from "../actions/types";
  
  const initialState = {
    isLoading: false,
    errors: false,
    message: null,
    data: []
  };
  
  export default function role(state = initialState, action) {
    switch (action.type) {
      case ROLE_RESET_MESSAGES:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: null
        }
      case ROLE_GET_LOADING:
        return {
          ...state,
          isLoading: true,
          message: "Obteniendo roles",
        };
      case ROLE_CREATE_LOADING:
        return {
          ...state,
          isLoading: true,
          message: "Creando rol..."
        }
      case ROLE_EDIT_LOADING:
        return {
          ...state,
          isLoading: true,
          message: "Actualizando rol..."
        }
      case ROLE_DELETE_LOADING:
        return {
          ...state,
          isLoading: true,
          message: "Eliminando rol..."
        };
      case ROLE_GET_SUCCESS:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: action.payload.message,
          data: action.payload.roles,
        };
      case ROLE_CREATE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: action.payload.message,
          data: [...state.data, action.payload.role],
        }
      case ROLE_EDIT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: action.payload.message,
          data: state.data.map((role) => 
            role.id === action.payload.role.id
              ? action.payload.role
              : role
          ),
        };
      case ROLE_DELETE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          errors: false,
          data: state.data.filter(
            (role) => role.id !== action.payload.id
          ),
          message: action.payload.message,
        }
      case ROLE_GET_FAILED:
        return {
          ...state,
          isLoading: false,
          errors: true,
          message: action.payload,
          data: [],
        };
      case ROLE_CREATE_FAILED:
        return {
          ...state,
          isLoading: false,
          errors: true,
          message: action.payload,
        };
      case ROLE_EDIT_FAILED:
        return {
          ...state,
          isLoading: false,
          errors: true,
          message: action.payload,
        };
      case ROLE_DELETE_FAILED:
        return {
          ...state,
          isLoading: false,
          errors: true,
          message: action.payload,
        }
      default:
        return { ...state };
    }
  }
  