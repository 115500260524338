import { useEffect, useState } from "react";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { GridActionsCellItem } from "@mui/x-data-grid";

import InfoTable from "../tables/InfoTable";
import { ResourceServerDialog, ConfirmDialog } from "../dialogs";

import { withPermissions } from "hocs";
import { rserver } from "actions";
import { ADMIN_RESOURCE_SERVERS_WRITE } from "constants";
import { hasPermission } from "utils/permissions";
import PageHeader from "../headers/PageHeader";

const ResourceServersView = ({
  isLoading,
  errors,
  message,
  rservers,
  getResourceServers,
  deleteResourceServer,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [currentResourceServer, setCurrentResourceServer] = useState(null);

  const [show, setShow] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setCurrentResourceServer(null);
  };

  const handleDeleteSubmit = () => {
    deleteResourceServer(currentResourceServer.id);
    handleDeleteClose();
  };

  const CreateResouceServer = withPermissions(
    () => (
      <Button
        variant="outlined"
        sx={{
          fontWeight: 600,
        }}
        onClick={() => setShow(true)}
      >
        Crear Servidor de Recursos
      </Button>
    ),
    [ADMIN_RESOURCE_SERVERS_WRITE],
    false
  );

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "server_name", headerName: "Nombre RS", width: 150, flex: 1 },
    {
      field: "server_description",
      headerName: "Descripción",
      width: 200,
      flex: 1,
    },
    { field: "server_uri", headerName: "URL", width: 150, flex: 1 },
    { field: "scope", headerName: "Scope Definido", width: 150, flex: 1 },
    { field: "server_id", headerName: "Client ID", width: 200, flex: 1 },
    {
      field: "server_secret",
      headerName: "Client Secret",
      width: 200,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Fecha de Creación",
      width: 200,
      flex: 1,
    },
    {
      field: "updated_at",
      headerName: "Fecha de Actualización",
      width: 200,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      hide: !hasPermission(ADMIN_RESOURCE_SERVERS_WRITE),
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          onClick={() => {
            setFormValues({
              id: params.row.id,
              data: {
                server_name: params.row.server_name,
                server_description: params.row.server_description,
                server_uri: params.row.server_uri,
                scope: params.row.scope,
              },
            });
            setShow(true);
          }}
          label="Editar Permiso"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon color="error" />}
          onClick={() => {
            setCurrentResourceServer(params.row);
            setOpenDelete(true);
          }}
          label="Eliminar Organización"
        />,
      ],
    },
  ];

  useEffect(() => {
    getResourceServers();
  }, [getResourceServers]);

  return (
    <div style={{ marginLeft: "325px", marginRight: "2%" }}>
      <ResourceServerDialog
        open={show}
        setOpen={setShow}
        formValues={formValues}
        setFormValues={setFormValues}
      />
      <ConfirmDialog
        open={openDelete}
        handleClose={handleDeleteClose}
        handleSubmit={handleDeleteSubmit}
      >
        ¿Está seguro que desea eliminar el Servidor de Recursos{" "}
        {currentResourceServer && currentResourceServer.server_name}?, esta
        acción es irreversible.
      </ConfirmDialog>
      <PageHeader
        title="Servidores de Recursos"
        children={<CreateResouceServer />}
      />

      <h3>Lista de Servidores de Recursos disponibles en Amadeus</h3>
      {isLoading ? (
        <CircularProgress size={40} />
      ) : errors && rservers === [] ? (
        message
      ) : rservers.length !== 0 ? (
        <Grid container>
          <InfoTable
            rows={rservers.map((rserver) => {
              return {
                id: rserver.id,
                server_name: rserver.server_name,
                server_description: rserver.server_description,
                server_uri: rserver.server_uri,
                scope: rserver.scope,
                server_id: rserver.server_id,
                server_secret: rserver.server_secret,
                created_at: new Date(rserver.created_at),
                updated_at: new Date(rserver.updated_at),
              };
            })}
            columns={columns}
          />
        </Grid>
      ) : (
        "No existen Servidores de Recursos registrados."
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  rservers: state.rserver.data,
  isLoading: state.rserver.isLoading,
  errors: state.rserver.errors,
  message: state.rserver.message,
});

const mapActionToProps = {
  getResourceServers: rserver.getResourceServers,
  deleteResourceServer: rserver.deleteResourceServer,
};

export default withPermissions(
  connect(mapStateToProps, mapActionToProps)(ResourceServersView),
  [ADMIN_RESOURCE_SERVERS_WRITE]
);
