import { useForm, Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";


import { nameRules, descriptionRules, organizationIdRules } from "./rules";

const PermissionForm = ({
  isEdit,
  values,
  organizations,
  submit,
  handleClose,
  disableButton,
  submitButtonText
}) => {
  const initialValues = {
    name: "",
    description: "",
    organization_id: ""
  };
  const { control, handleSubmit } = useForm({
    defaultValues: values ?? initialValues,
  });

  const onSubmit = (data) => {
    submit(data);
  }

  const inputSX = { mt: 1, mb: 1 };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Controller
          name="name"
          required={true}
          disabled={isEdit}
          rules={nameRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                disabled={isEdit}
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                label="Nombre del Permiso"
                variant="standard"
                color="primary"
                helperText={error && error.message}
                sx={inputSX}
              />
            );
          }}
        />
        <Controller
          name="description"
          required={true}
          rules={descriptionRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                label="Descripción del Permiso"
                variant="standard"
                color="primary"
                multiline
                rows={3}
                helperText={error && error.message}
                sx={inputSX}
              />
            );
          }}
        />
        <Controller
          name="organization_id"
          required={true}
          disabled={isEdit}
          rules={organizationIdRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                disabled={isEdit}
                select
                label="Organización"
                variant="standard"
                name={name}
                error={ error !== undefined }
                helperText={error && error.message}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                sx={inputSX}
              >
                { organizations.length || <MenuItem disabled>No options</MenuItem> }
                {organizations.map((org) => (
                  <MenuItem value={org.id} key={org.id}>
                    {org.name}
                  </MenuItem>
                ))}}
              </TextField>
            )
          }}
        />
        <Box sx={{ mt: 2, textAlign: "end" }}>
          <Button onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" disabled={disableButton}>
            {submitButtonText ? submitButtonText : "Enviar"}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default PermissionForm;