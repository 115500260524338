import { getData, postData, putData, deleteData } from "./base";

export const getRoles = async () => {
    return await getData("role");
}

export const createRole = async (newRole) => {
    return await postData("role", newRole);
}

export const editRole = async (id, newRole) => {
    return await putData(`role?role_id=${id}`, newRole);
}

export const deleteRole = async (id) => {
    return await deleteData(`role?role_id=${id}`);
}
