import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_FAILED,
  LOGIN_DEFAULT,
} from "../actions/types";

import {
  LOCAL_STORAGE_USER_TOKEN,
  LOCAL_STORAGE_USERNAME,
  LOCAL_STORAGE_TOKEN_TYPE,
  LOCAL_STORAGE_EXP,
  LOCAL_STORAGE_ROLES,
  LOCAL_STORAGE_PERMISSIONS
} from "../constants";

const baseState = {
  token: null,
  username: null,
  isAuthenticated: false,
  permissions: null,
  isLoading: false,
  errors: null,
};


const initialState =
  localStorage.getItem(LOCAL_STORAGE_USER_TOKEN) === null
    ? baseState
    : {
      ...baseState,
      isAuthenticated: true,
      token: localStorage.getItem(LOCAL_STORAGE_USER_TOKEN),
      username: localStorage.getItem(LOCAL_STORAGE_USERNAME),
      token_type: localStorage.getItem(LOCAL_STORAGE_TOKEN_TYPE),
      exp: localStorage.getItem(LOCAL_STORAGE_EXP),
      roles: localStorage.getItem(LOCAL_STORAGE_ROLES).split(','),
      permissions: JSON.parse(localStorage.getItem(LOCAL_STORAGE_PERMISSIONS))
    };

export default function user(state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_DEFAULT:
      return {
        ...baseState,
      }
    case LOGIN_SUCCESS:
      localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, action.payload.token);
      localStorage.setItem(LOCAL_STORAGE_USERNAME, action.payload.username);
      localStorage.setItem(LOCAL_STORAGE_TOKEN_TYPE, action.payload.token_type);
      localStorage.setItem(LOCAL_STORAGE_EXP, action.payload.exp);
      localStorage.setItem(LOCAL_STORAGE_ROLES, action.payload.roles);
      localStorage.setItem(LOCAL_STORAGE_PERMISSIONS, JSON.stringify(action.payload.permissions));
      return {
        token: action.payload.token,
        username: action.payload.username,
        roles: action.payload.roles,
        permissions: action.payload.permissions,
        isAuthenticated: true,
        isLoading: false,
        errors: null,
      };
    case LOGIN_FAILED:
      return {
        errors: action.payload,
        token: null,
        username: null,
        isAuthenticated: false,
        isLoading: false,
        roles: null,
        permissions: null
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_USERNAME);
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_TYPE);
      localStorage.removeItem(LOCAL_STORAGE_EXP);
      localStorage.removeItem(LOCAL_STORAGE_ROLES);
      localStorage.removeItem(LOCAL_STORAGE_PERMISSIONS);
      return {
        username: null,
        errors: null,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        roles: null,
        permissions: null
      };
    default:
      return { ...state };
  }
}