import { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import no_image from "../../../images/no_image.png";

import {
  clientNameRules,
  clientDescriptionRules,
  clientUriRules,
  redirectUrisRules,
  grantTypesRules,
  responseTypesRules,
  tokenEndpointAuthMethodRules,
  scopesRules,
  imageRules
} from "./rules";

import { applicationImagePreviewClass } from "./commons";

const AppForm = ({
  scopes,
  values,
  submit,
  handleClose,
  disableButton,
  submitButtonText
}) => {
  const initialValues = {
    client_name: "",
    client_description: "",
    client_uri: "",
    redirect_uris: "",
    grant_types: "",
    response_types: "",
    scopes: [],
    token_endpoint_auth_method: "",
    image: "",
  }

  const imageRef = useRef();
  const [preview, setPreview] = useState("");
  const { control, handleSubmit } = useForm({
    defaultValues: values ?? initialValues
  })

  const handlePicture = () => {
    const files = imageRef.current.firstChild.files;
    if (files && files[0]) {
      const image = files[0];
      const objectURL = URL.createObjectURL(image);
      setPreview(objectURL);
    } else {
      setPreview("");
    }
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const inputSX = { mt: 1, mb: 1 };
  
  const onSubmit = (data) => {
    const input = imageRef.current.firstChild;
    const image = input.files[0];
    data.image = image;
    submit(data);
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autocomplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      > 
        <Controller
          name="image"
          rules={imageRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name },
            fieldState: { error, isTouched }
          }) => (
            <FormControl
              error={error !== undefined}
              variant="standard"
              color="primary"
              fullWidth
              required
              sx={{ mb: 2 }}
            >
              <Button variant="text" component="label" sx={{margin: "auto"}}>
                <Input
                  required
                  ref={imageRef}
                  accept="image/*"
                  type="file"
                  name={name}
                  value={value}
                  onChange={(event) => {
                    handlePicture();
                    onChange(event);
                  }}
                  onBlur={onBlur}
                  sx={{display: "none"}}
                />
                <img
                  src={preview || no_image}
                  alt="preview"
                  style={{
                    ...applicationImagePreviewClass,
                    borderColor: error ? 'red' : ''
                  }}
                />
              </Button>
              {error && (
                <FormHelperText>{error.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
        <Controller
          name="client_name"
          rules={clientNameRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                required
                label="Nombre de la Aplicación"
                variant="standard"
                color="primary"
                helperText={error?.message}
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                sx={inputSX}
              />
            );
          }}
        />
        <Controller
          name="client_description"
          rules={clientDescriptionRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                required
                label="Descripción de la Aplicación"
                variant="standard"
                color="primary"
                helperText={error?.message}
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                sx={inputSX}
              />
            );
          }}
        />
        <Controller
          name="client_uri"
          rules={clientUriRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                required
                label="URL de la Aplicación"
                variant="standard"
                color="primary"
                helperText={error?.message}
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                sx={inputSX}
              />
            );
          }}
        />
        <Controller
          name="redirect_uris"
          rules={redirectUrisRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                required
                label="URL de redirección"
                variant="standard"
                color="primary"
                helperText={error?.message}
                error={error !== undefined}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                sx={inputSX}
              />
            );
          }}
        />
        <Controller
          name="scopes"
          rules={scopesRules}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error }
          }) =>{
            return (
              <Autocomplete
                multiple
                value={value}
                options={scopes}
                disableCloseOnSelect
                onChange={(_, data) => {
                  onChange(data);
                }}
                // getOptionLabel={(option) => option.name }
                // isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, { selected }) => {
                  return (<li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                    </li>
                  )}
                }
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Scopes"
                    variant="standard"
                    color="primary"
                    error={ error !== undefined }
                    helperText={error?.message}
                    sx={inputSX}
                  />
                )}
              />
            );
          }}
        />
        <Controller
          name="grant_types"
          rules={grantTypesRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                required
                select
                label="Grant Types"
                variant="standard"
                name={name}
                helperText={error?.message}
                error={ error !== undefined }
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                sx={inputSX}
              >
                <MenuItem value="authorization_code">Authorization Code</MenuItem>
              </TextField>
            )
          }}
        />
        <Controller
          name="response_types"
          rules={responseTypesRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                required
                select
                label="Response Types"
                variant="standard"
                name={name}
                helperText={error?.message}
                error={ error !== undefined }
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                sx={inputSX}
              >
                <MenuItem value="code">Code</MenuItem>
              </TextField>
            )
          }}
        />
        <Controller
          name="token_endpoint_auth_method"
          rules={tokenEndpointAuthMethodRules}
          control={control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { error },
          }) => {
            return (
              <TextField
                required
                select
                label="Token Endpoint Auth Method"
                variant="standard"
                name={name}
                helperText={error?.message}
                error={ error !== undefined }
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                sx={inputSX}
              >
                <MenuItem value="none">Ninguno (Aplicación Pública)</MenuItem>
                <MenuItem value="client_secret_post">Client Secret Post</MenuItem>
              </TextField>
            )
          }}
        />
        <Box sx={{ mt: 2, textAlign: "end" }}>
          <Button onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" disabled={disableButton}>
            {submitButtonText ?? "Enviar"}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default AppForm;