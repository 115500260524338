import {
    PROFILE_GET_LOADING,
    PROFILE_GET_SUCCESS,
    PROFILE_GET_FAILED,
    PROFILE_CREATE_LOADING,
    PROFILE_CREATE_SUCCESS,
    PROFILE_CREATE_FAILED,
    PROFILE_RESET_MESSAGES,
  } from "../actions/types";
  
  const initialState = {
    isLoading: false,
    errors: false,
    message: null,
    data: {}
  };
  
  export default function profile(state = initialState, action) {
    switch (action.type) {
      case PROFILE_RESET_MESSAGES:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: null
        }
      case PROFILE_GET_LOADING:
        return {
          ...state,
          isLoading: true,
          message: "Obteniendo perfil",
        };
      case PROFILE_CREATE_LOADING:
        return {
          ...state,
          isLoading: true,
          message: "Creando perfil",
        };
      case PROFILE_GET_SUCCESS:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: action.payload.message,
          data: action.payload.profile,
        };
      case PROFILE_CREATE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          errors: false,
          message: action.payload.message,
          data: action.payload.profile,
        };
      case PROFILE_GET_FAILED:
        return {
          ...state,
          isLoading: false,
          errors: true,
          message: action.payload.message,
          data: {},
        };
      case PROFILE_CREATE_FAILED:
        return {
          ...state,
          isLoading: false,
          errors: true,
          message: action.payload,
        };
      default:
        return { ...state };
    }
  }
  