import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";

import { user } from "../actions";
import LoginBox from "../components/boxes/LoginBox";

const Login = ({ isAuthenticated, isLoading, errors, login, toDefault }) => {
  const [message, setMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation();

  const displayMessage = (message) => {
    setDisableButton(true);
    setMessage(message);
    toDefault();
    setDisableButton(false);
    if (location.state?.error) {
      delete location.state["error"]
    }
  };

  useEffect(() => {
    let { state } = location;

    if (errors) {
      setDisableButton(true);
      displayMessage(errors);
    }
    if (state?.error === "oauth") {
      displayMessage(
        "La aplicación a la cual intenta autorizar, es inválida."
      );
    } else if (state?.error === "token_expired") {
      displayMessage(
        "Tu sesión ha expirado, por favor inicia sesión nuevamente."
      );
    } else if (state?.error === "invalid_permission") {
      displayMessage("No tienes permisos para acceder a este recurso.");
    }
  }, [errors]);

  useEffect(() => {
    if (isAuthenticated && !location.state?.error) {
      if (location.state?.next) {
        navigate("../" + decodeURIComponent(location.state.next), { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [isAuthenticated, navigate]);

  return (
    <Container
      maxWidth="false"
      style={{
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.default
            : theme.palette.primary.main,
        height: "100vh",
      }}
    >
      <LoginBox
        isLoading={isLoading}
        hasToDisableButton={disableButton}
        message={message}
        senderDataFn={login}
      />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  isLoading: state.user.isLoading,
  errors: state.user.errors,
});

const mapActionsToProps = {
  login: user.login,
  toDefault: user.toDefault,
};

export default connect(mapStateToProps, mapActionsToProps)(Login);
