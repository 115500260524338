import { useEffect } from "react";
import { connect } from "react-redux";

import { organization } from "actions";
import OrganizationForm from "components/forms/OrganizationForm";
import FormDialog from "./FormDialog";

function OrganizationDialog({
  open,
  close,
  setOpen,
  createOrganization,
  editOrganization,
  isLoading,
  error,
  message,
  formValues,
  setFormValues,
}) {
  const handleClose = () => {
    setFormValues(null);
    setOpen(false);
  };

  const onSubmit = (data) => {
    return !formValues
      ? createOrganization(data)
      : editOrganization(formValues.id, data);
  };

  useEffect(() => {
    if (close) handleClose();
  }, [close]);

  return (
    <FormDialog
      open={open}
      title={
        formValues === null
          ? "Crear Organización"
          : `Editar Organización: #${formValues.id} ${formValues.data.name}`
      }
      description={
        <span>
          Estás apunto de {!formValues ? "crear" : "editar"} una
          organización en Amadeus. <br />
          Ingresa los datos solicitados.
        </span>
      }
      showMessage={error && !!message}
      errorMessage={message}
      form={
        <OrganizationForm
          values={formValues?.data}
          submit={onSubmit}
          handleClose={handleClose}
          disableButton={isLoading || !!message}
          submitButtonText={
            !formValues ? "Crear Organización" : "Editar Organización"
          }
        />
      }
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.organization.isLoading,
    error: state.organization.errors,
    message: state.organization.message,
    close: state.displayAlert.display,
    open: ownProps.open,
    setOpen: ownProps.setOpen,
    formValues: ownProps.formValues,
    setFormValues: ownProps.setFormValues,
  };
};

const mapActionToProps = {
  createOrganization: organization.createOrganization,
  editOrganization: organization.editOrganization,
};

export default connect(mapStateToProps, mapActionToProps)(OrganizationDialog);
