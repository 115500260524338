export const nameRules = {
  required: {
    value: true,
    message: "El nombre del rol es requerido",
  },
  minLength: {
    value: 3,
    message: "El nombre del rol debe tener como mínimo 3 carácteres.",
  },
  maxLength: {
    value: 50,
    message: "El nombre del rol no puede sobrepasar los 50 carácteres.",
  },
}

export const descriptionRules = {
  required: {
    value: true,
    message: "La descripción es requerida",
  },
  minLength: {
    value: 3,
    message: "La descripción debe tener como mínimo 3 carácteres.",
  },
  maxLength: {
    value: 255,
    message: "La descripción no puede sobrepasar los 255 carácteres.",
  },
}

export const permissionRules = {
  required: {
    value: true,
    message: "Los permisos son requeridos"
  }
}