export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_DEFAULT = "LOGIN_DEFAULT";

// Permissions
export const PERMISSION_RESET_MESSAGES = "PERMISSION_RESET_MESSAGES";
export const PERMISSION_GET_LOADING = "PERMISSION_GET_LOADING";
export const PERMISSION_GET_SUCCESS = "PERMISSION_GET_SUCCESS";
export const PERMISSION_GET_FAILED = "PERMISSION_GET_FAILED";
export const PERMISSION_CREATE_LOADING = "PERMISSION_CREATE_LOADING";
export const PERMISSION_CREATE_SUCCESS = "PERMISSION_CREATE_SUCCESS";
export const PERMISSION_CREATE_FAILED = "PERMISSION_CREATE_FAILED";
export const PERMISSION_EDIT_LOADING = "PERMISSION_EDIT_LOADING";
export const PERMISSION_EDIT_SUCCESS = "PERMISSION_EDIT_SUCCESS";
export const PERMISSION_EDIT_FAILED = "PERMISSION_EDIT_FAILED";
export const PERMISSION_DELETE_FAILED = "PERMISSION_DELETE_FAILED";
export const PERMISSION_DELETE_LOADING = "PERMISSION_DELETE_LOADING";
export const PERMISSION_DELETE_SUCCESS = "PERMISSION_DELETE_SUCCESS";

// User Administration Actions
export const USER_RESET_MESSAGES = "USER_RESET_MESSAGES";
export const USER_GET_LOADING = "USER_GET_LOADING";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAILED = "USER_GET_FAILED";
export const USER_CREATE_LOADING = "USER_CREATE_LOADING";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAILED = "USER_CREATE_FAILED";
export const USER_UPDATE_LOADING = "USER_UPDATE_LOADING";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";
export const USER_DELETE_LOADING = "USER_DELETE_LOADING";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILED = "USER_DELETE_FAILED";

// Profile config
export const PROFILE_GET_LOADING = "PROFILE_GET_LOADING";
export const PROFILE_GET_SUCCESS = "PROFILE_GET_SUCCESS";
export const PROFILE_GET_FAILED = "PROFILE_GET_FAILED";
export const PROFILE_CREATE_LOADING = "PROFILE_CREATE_LOADING";
export const PROFILE_CREATE_SUCCESS = "PROFILE_CREATE_SUCCESS";
export const PROFILE_CREATE_FAILED = "PROFILE_CREATE_FAILED";
export const PROFILE_RESET_MESSAGES = "PROFILE_RESET_MESSAGES";
export const PROFILE_DELETE_LOADING = "PROFILE_DELETE_LOADING";
export const PROFILE_DELETE_SUCCESS = "PROFILE_DELETE_SUCCESS";
export const PROFILE_DELETE_FAILED = "PROFILE_DELETE_FAILED";

// Roles
export const ROLE_RESET_MESSAGES = "ROLE_RESET_MESSAGES";
export const ROLE_GET_LOADING = "ROLE_GET_LOADING";
export const ROLE_GET_SUCCESS = "ROLE_GET_SUCCESS";
export const ROLE_GET_FAILED = "ROLE_GET_FAILED";
export const ROLE_CREATE_LOADING = "ROLE_CREATE_LOADING";
export const ROLE_CREATE_SUCCESS = "ROLE_CREATE_SUCCESS";
export const ROLE_CREATE_FAILED = "ROLE_CREATE_FAILED";
export const ROLE_EDIT_LOADING = "ROLE_EDIT_LOADING";
export const ROLE_EDIT_SUCCESS = "ROLE_EDIT_SUCCESS";
export const ROLE_EDIT_FAILED = "ROLE_EDIT_FAILED";
export const ROLE_DELETE_LOADING = "ROLE_DELETE_LOADING";
export const ROLE_DELETE_SUCCESS = "ROLE_DELETE_SUCCESS";
export const ROLE_DELETE_FAILED = "ROLE_DELETE_FAILED";

// Applications
export const CLIENT_RESET_MESSAGES = "CLIENT_RESET_MESSAGES";
export const CLIENT_GET_LOADING = "CLIENT_GET_LOADING";
export const CLIENT_GET_SUCCESS = "CLIENT_GET_SUCCESS";
export const CLIENT_GET_FAILED = "CLIEN_GETT_FAILED";
export const CLIENT_CREATE_LOADING = "CLIENT_CREATE_LOADING";
export const CLIENT_CREATE_SUCCESS = "CLIENT_CREATE_SUCCESS";
export const CLIENT_CREATE_FAILED = "CLIENT_CREATE_FAILED";
export const CLIENT_UPDATE_LOADING = "CLIENT_UPDATE_LOADING";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
export const CLIENT_UPDATE_FAILED = "CLIENT_UPDATE_FAILED";
export const CLIENT_DELETE_LOADING = "CLIENT_DELETE_LOADING";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_DELETE_FAILED = "CLIENT_DELETE_FAILED";
export const CLIENT_REFRESH_SECRET_LOADING = "CLIENT_REFRESH_SECRET_LOADING";
export const CLIENT_REFRESH_SECRET_SUCCESS = "CLIENT_REFRESH_SECRET_SUCCESS";
export const CLIENT_REFRESH_SECRET_FAILED = "CLIENT_REFRESH_SECRET_FAILED";

// Resource Server
export const RESOURCE_SERVER_RESET_MESSAGES = "RESOURCE_SERVER_RESET_MESSAGES";
export const RESOURCE_SERVER_GET_LOADING = "RESOURCE_SERVER_GET_LOADING";
export const RESOURCE_SERVER_GET_SUCCESS = "RESOURCE_SERVER_GET_SUCCESS";
export const RESOURCE_SERVER_GET_FAILED = "RESOURCE_SERVER_GET_FAILED";
export const RESOURCE_SERVER_CREATE_LOADING = "RESOURCE_SERVER_CREATE_LOADING";
export const RESOURCE_SERVER_CREATE_SUCCESS = "RESOURCE_SERVER_CREATE_SUCCESS";
export const RESOURCE_SERVER_CREATE_FAILED = "RESOURCE_SERVER_CREATE_FAILED";
export const RESOURCE_SERVER_EDIT_LOADING = "RESOURCE_SERVER_EDIT_LOADING";
export const RESOURCE_SERVER_EDIT_SUCCESS = "RESOURCE_SERVER_EDIT_SUCCESS";
export const RESOURCE_SERVER_EDIT_FAILED = "RESOURCE_SERVER_EDIT_FAILED";
export const RESOURCE_SERVER_DELETE_LOADING = "RESOURCE_SERVER_DELETE_LOADING";
export const RESOURCE_SERVER_DELETE_SUCCESS = "RESOURCE_SERVER_DELETE_SUCCESS";
export const RESOURCE_SERVER_DELETE_FAILED = "RESOURCE_SERVER_DELETE_FAILED";

// Organization
export const ORGANIZATION_RESET_MESSAGES = "ORGANIZATION_RESET_MESSAGES";
export const ORGANIZATION_GET_LOADING = "ORGANIZATION_GET_LOADING";
export const ORGANIZATION_GET_SUCCESS = "ORGANIZATION_GET_SUCCESS";
export const ORGANIZATION_GET_FAILED = "ORGANIZATION_GET_FAILED";
export const ORGANIZATION_CREATE_LOADING = "ORGANIZATION_CREATE_LOADING";
export const ORGANIZATION_CREATE_SUCCESS = "ORGANIZATION_CREATE_SUCCESS";
export const ORGANIZATION_CREATE_FAILED = "ORGANIZATION_CREATE_FAILED";
export const ORGANIZATION_EDIT_LOADING = "ORGANIZATION_EDIT_LOADING";
export const ORGANIZATION_EDIT_SUCCESS = "ORGANIZATION_EDIT_SUCCESS";
export const ORGANIZATION_EDIT_FAILED = "ORGANIZATION_EDIT_FAILED";
export const ORGANIZATION_DELETE_LOADING = "ORGANIZATION_DELETE_LOADING";
export const ORGANIZATION_DELETE_SUCCESS = "ORGANIZATION_DELETE_SUCCESS";
export const ORGANIZATION_DELETE_FAILED = "ORGANIZATION_DELETE_FAILED";

export const ACTIVE_TOKEN = "ACTIVE_TOKEN";
export const EXPIRED_TOKEN = "EXPIRED_TOKEN";

// Alert displayed on screen
export const DISPLAY_ALERT = "DISPLAY_ALERT";
export const DISPLAY_ALERT_FADE = "DISPLAY_ALERT_FADE";
export const DISPLAY_ALERT_CLEAN = "DISPLAY_ALERT_CLEAN";