import { useState } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";

const validate = {
  username: (_) => null,
  password: (_) => null,
};

const initialValues = {
  username: "",
  password: "",
};

const inputSX = { mt: 1, mb: 1 };

const LoginForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box component="form" noValidate autoComplete="off">
      <TextField
        error={props.errors.username !== undefined ? true : false}
        value={props.values.username}
        onChange={props.handleChange}
        onKeyPress={(e) => props.onEnterKeyPress(e, props.senderDataFn)}
        onBlur={props.handleBlur}
        name="username"
        required
        label="Nombre de Usuario"
        color={"primary"}
        fullWidth
        sx={inputSX}
        helperText={props.touched.username && props.errors.username}
      />
      <FormControl
        error={props.errors.password !== undefined ? true : false}
        onKeyPress={(e) => props.onEnterKeyPress(e, props.senderDataFn)}
        fullWidth
        required
        color={"primary"}
        variant="outlined"
        sx={inputSX}
      >
        <InputLabel htmlFor="input-password">Contraseña</InputLabel>
        <OutlinedInput
          id="input-password"
          type={showPassword ? "text" : "password"}
          value={props.values.password}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          name="password"
          required
          aria-describedby="password-error-text"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Contraseña"
        />
        {props.touched.password && props.errors.password && (
          <FormHelperText id="password-error-text">
            {props.errors.password}
          </FormHelperText>
        )}
      </FormControl>
      <Box sx={{ mt: 2, textAlign: "end" }}>
        <LoadingButton
          onClick={(e) => props.handleSubmit(e, props.senderDataFn)}
          loading={props.isLoading}
          disabled={props.hasToDisableButton}
          fullWidth
          variant="contained"
          color={"primary"}
        >
          Entrar
        </LoadingButton>
      </Box>
    </Box>
  );
};

export { LoginForm, initialValues, validate };
