import { useEffect, useContext, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { Navbar, Sidebar, CreateAlert } from "../components";
import { user, profile } from "../actions";
import { PermissionsContext } from "../contexts/permission-context";

import "../components/dualvision/style.css";

function Home({
  userCurrentPermissions,
  isAuthenticated,
  token,
  isToken,
  logout,
  getProfile
}) {
  const navigate = useNavigate();
  
  const { userPermissions, setUserPermissions } = useContext(PermissionsContext);
  const [ profileIsChecked, setProfileIsChecked ] = useState(false);
  const drawerWidth = 300;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login?error=token_expired", { replace: true });
    }
  }, [isAuthenticated, token, navigate]);

  useEffect(() => {
    if (userCurrentPermissions) {
      setUserPermissions(userCurrentPermissions);
    }
  })

  useEffect(() => {
    if (isAuthenticated && isToken) {
      logout();
      navigate("/login", { replace: true });
    }
  }, [isAuthenticated, isToken]);
  
  useEffect(() => {
    let apiIsSuscribed = true;  
    (async function checkProfile() {
      await getProfile()
    })().then(() => {
      if (apiIsSuscribed) {
        setProfileIsChecked(true);
      }
    })
    return () => {
      apiIsSuscribed = false;
    }
  }, [getProfile]);

  return isAuthenticated && profileIsChecked && (
    <Grid direction="row" container>
      <Sidebar drawerWidth={drawerWidth} /> 
      <Navbar drawerWidth={drawerWidth} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginTop: "5%",
          position: "absolute",
          width: "100%",
        }}
        fullWidth
      >
        <Outlet />
      </Box>
      <CreateAlert />
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  userCurrentPermissions: state.user.permissions,
  isAuthenticated: state.user.isAuthenticated,
  token: state.user.token,
  isToken: state.isToken.token
});

const mapActionToProps = {
  logout: user.logout,
  getProfile: profile.getProfile,
};

export default connect(mapStateToProps, mapActionToProps)(Home);
