import {
    ACTIVE_TOKEN,
    EXPIRED_TOKEN,
  } from "../actions/types";
  
  const initialState = {
    token: false
  };
  
  export default function isToken(state = initialState, action) {
    switch (action.type) {
      case ACTIVE_TOKEN:
        return {
          ...state,
          token: true,
        };
      case EXPIRED_TOKEN:
        return {
          ...state,
          token: false,
        };
      default:
        return { ...state };
    }
  }
  