import { getData, postData, putData, deleteData } from "./base";

export const getResourceServers = async () => {
    return await getData("rserver");
}

export const createResourceServer = async (data) => {
    return await postData("rserver", data);
}

export const editResourceServer = async (id, newRServer) => {
    return await putData(`rserver?rserver_id=${id}`, newRServer);
}

export const deleteResourceServer = async (id) => {
    return await deleteData(`rserver?rserver_id=${id}`);
}